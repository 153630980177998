const cardPricing = {
  card: {
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 5)",
    borderRadius: "5px",
  },
  cardHover: {
    boxShadow: "0 1px 14px 0 rgba(0, 0, 0, 5)",
    borderRadius: "5px",
  }
  };
  export default cardPricing;