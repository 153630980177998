import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import scrollIntoView from "scroll-into-view-if-needed";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import { connect } from "react-redux";
import {
  getlocalAreas,
  verifyMobile,
  removeOrder,
  verifyCode,
} from "actions/retailActions";

class PhoneForm extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes, local_area, phonemove, changePhone, verify_mobile } = props;
    this.state = {
      changePhone: changePhone,
      phonemove: phonemove,
      local_area: local_area,
      classSnackbar: classes.hideSnackbar,
      response: "",
      code: "",
      loading_verifyMobile: false,
      errors: {
        phonemove: false,
        code: false,
      },
      verify_mobile: verify_mobile,
    };
  }

  async componentDidMount() {
    if (!this.state.loading_verifyMobile) {
      this.setState({ loading_verifyMobile: true });
      await this.props.getlocalAreas();
    }
    this.setState({ loading_verifyMobile: false });
  }

  onClick = async (e) => {
    e.preventDefault();

    const {
      classes,
      firstName,
      lastName,
      email,
      phonemobile,
      address,
      accept,
      verify_mobile,
      city,
      products,
      plans,
      top_up,
    } = this.props;

    const { phonemove } = this.state;

    if (verify_mobile.order_id !== undefined) {
      if (!this.state.loading_verifyMobile) {
        this.setState({
          loading_verifyMobile: true,
          verify_mobile: { verify: false },
        });
        const res_back = await this.props.removeOrder(
          verify_mobile.order_id,
          verify_mobile.dsfsafw352
        );
        if (res_back) {
          // display error mesage
          this.setState({
            loading_verifyMobile: false,
            classSnackbar: classes.errorSnackbar,
            response: "Something went wrong again.",
          });
        }
      }
    }
    this.setState({ loading_verifyMobile: false });
    this.props.handleBack(
      firstName,
      lastName,
      email,
      phonemobile,
      address,
      accept,
      phonemove,
      city,
      products,
      plans,
      top_up
    );
  };
  onClickChnagePhone = () =>
    this.setState({
      changePhone: !this.state.changePhone,
    });
  onChange = (e) => {
    this.setState(
      e.target.id === "changePhone"
        ? {
            [e.target.id]: e.target.checked,
          }
        : (e.target.value === "" && e.target.id === "phonemove") ||
          (e.target.value.length < 10 && e.target.id === "phonemove") ||
          (e.target.value.length > 10 && e.target.id === "phonemove") ||
          (e.target.id === "phonemove" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : (e.target.value === "" && e.target.id === "code") ||
          (e.target.value.length < 5 && e.target.id === "code") ||
          (e.target.value.length > 5 && e.target.id === "code") ||
          (e.target.id === "code" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : e.target.name === "local_area"
        ? {
            [e.target.name]: e.target.value,
          }
        : {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: false,
            },
          }
    );
  };
  onBlur = (e) => {
    this.setState(
      (e.target.value === "" && e.target.id === "phonemove") ||
        (e.target.value.length < 10 && e.target.id === "phonemove") ||
        (e.target.value.length > 10 && e.target.id === "phonemove") ||
        (e.target.id === "phonemove" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            errors: {
              phonemove: true,
            },
          }
        : (e.target.value === "" && e.target.id === "code") ||
          (e.target.value.length < 5 && e.target.id === "code") ||
          (e.target.value.length > 5 && e.target.id === "code") ||
          (e.target.id === "code" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true,
            },
          }
        : {
            errors: {
              phonemove: false,
            },
          }
    );
  };
  onVerify = async (e) => {
    e.preventDefault();

    const {
      classes,
      firstName,
      lastName,
      email,
      phonemobile,
      phonemove,
      address,
      city,
    } = this.props;

    const { loading_verifyMobile } = this.state;

    if (!loading_verifyMobile) {
      this.setState({ loading_verifyMobile: true });
      // sent axios loading
      const res = await this.props.verifyMobile(
        firstName,
        lastName,
        email,
        phonemobile,
        phonemove,
        address,
        city
      );
      if (res) {
        // display error mesage
        this.setState({
          loading_verifyMobile: false,
          classSnackbar: classes.errorSnackbar,
          response: "Something went wrong again. Check your mobile phone.",
        });
      }
    }

    this.setState({ loading_verifyMobile: false });
    if (this.props.verify_mobile.verify === true) {
      this.setState({ verify_mobile: { verify: true } });
      // response with veryfication code true - enter veryfication code Input
      this.setState({
        classSnackbar: classes.successSnackbar,
        response: "The sms message was sent successfully.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
    } else {
      // display error mesage
      this.setState({
        classSnackbar: classes.errorSnackbar,
        response: "Something went wrong again. Check your mobile phone.",
      });
    }
    return;
  };
  onSubmit = async (e) => {
    e.preventDefault();

    const {
      classes,
      firstName,
      lastName,
      email,
      phonemobile,
      address,
      city,
      verify_mobile,
      accept,
      phonemove,
      products,
      plans,
      top_up,
    } = this.props;

    const { code, loading_verifyMobile, local_area } = this.state;

    // Check For Errors
    if (
      code === "" ||
      code === undefined ||
      (code && code.length < 5) ||
      (code && code.length > 5) ||
      (code && !code.match(/^[0-9\b]+$/))
    ) {
      this.setState({
        errors: { code: true },
        classSnackbar: classes.errorSnackbar,
        response: "The SMS verification code is invalid.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
      return;
    }
    if (verify_mobile.order_id !== undefined) {
      if (!loading_verifyMobile) {
        this.setState({ loading_verifyMobile: true });
        const res = await this.props.verifyCode(
          verify_mobile.order_id,
          verify_mobile.dsfsafw352,
          code
        );
        if (res) {
          // display error mesage
          this.setState({
            loading_verifyMobile: false,
            classSnackbar: classes.errorSnackbar,
            response: "The SMS verification code is invalid.",
          });
        }
      }
    }
    this.setState({ loading_verifyMobile: false });
    if (this.props.verify_code === true) {
      this.setState({
        classSnackbar: classes.successSnackbar,
        response: "Your mobile number has been confirmed.",
      });
      // handle next
      this.props.handleNext(
        firstName,
        lastName,
        email,
        phonemobile,
        address,
        accept,
        phonemove,
        city,
        products,
        verify_mobile,
        plans,
        top_up,
        verify_mobile.order_id
      );
    } else {
      // display error mesage
      this.setState({
        classSnackbar: classes.errorSnackbar,
        response: "The SMS verification code is invalid.",
      });
      const node = document.getElementById("code");
      scrollIntoView(node, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "center",
        inline: "center",
      });
    }

    return;
  };
  /*
   await this.props.createOrder(
            firstName,
            lastName,
            email,
            address,
            phonemove,
            city,
            country,
            area,
            phonemobile
          );
  */
  render() {
    const { activeStep, classes, phonemobile, loading_phoneForm } = this.props;
    const {
      verify_mobile,
      errors,
      classSnackbar,
      response,
      code,
      loading_verifyMobile,
    } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            MOBILE VERIFICATION
          </Typography>
          <Grid container>
            <GridItem xs={12}>
              <Typography>
                For security reasons Callinmarsat must verify your mobile number
                before you register. Check if your number is displayed correctly
                here, then press mobile verification. Otherwise go back to the
                previous step!
              </Typography>
              <CustomInput
                type="text"
                labelText="Mobile"
                id="phonemobile"
                name="phonemobile"
                value={phonemobile}
                onChange={this.onChange}
                disabled={true}
                helperText="Fill in your mobile number with the country code. e.g. 306988123412 or 447578754922"
                formControlProps={{
                  fullWidth: true,
                }}
                /*inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+30</InputAdornment>
                  )
                }}*/
              />
              {verify_mobile.verify && (
                <CustomInput
                  error={errors.code ? true : false}
                  type="text"
                  labelText="Verification Code"
                  id="code"
                  name="code"
                  value={code}
                  onChange={this.onChange}
                  onFocus={this.onBlur}
                  onBlur={this.onBlur}
                  disabled={loading_verifyMobile}
                  helperText="Check your mobile, we have sent you an SMS with a password."
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              )}
            </GridItem>
          </Grid>

          <SnackbarContent
            className={classSnackbar}
            aria-describedby="client-snackbar"
            message={response}
          />
          <GridContainer className={classes.buttons}>
            <GridItem xs={12} sm={12} md={12} className={classes.textEnd}>
              {activeStep !== 0 && (
                <Button
                  onClick={this.onClick}
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  BACK
                </Button>
              )}
              {!verify_mobile.verify ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onVerify}
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  {"Mobile number verification"}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  disabled={loading_verifyMobile}
                >
                  {"NEXT"}
                </Button>
              )}
              {loading_phoneForm && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {loading_verifyMobile && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </GridItem>
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}

PhoneForm.propTypes = {
  classes: PropTypes.object,
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};

const mapStateToProps = (state) => ({
  local_areas: state.retail_reducer.local_areas,
  verify_mobile: state.retail_reducer.verify_mobile,
  verify_code: state.retail_reducer.verify_code,
  retail_plan_pack: state.retail_reducer.retail_plan_pack,
});

export default connect(
  mapStateToProps,
  { getlocalAreas, verifyMobile, removeOrder, verifyCode }
)(withStyles(workStyle)(PhoneForm));
