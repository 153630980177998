import {
  ERROR_AXIOS,
  CHECK_EMAIL,
  REMOVE_ORDER,
  CREATE_ORDER,
  VERIFY_MOBILE,
  VERIFY_CODE,
  GET_PLANS,
  UPDATE_ALPHA_ORDER,
  CHECK_ORDER,
  GET_DID_COUNTRIES,
  GET_AREAS,
  GET_DID_COUNTRY,
} from "../actions/types";

const initialState = {
  error_axios: {},
  check_email: {},
  loading: true,
  order_axios: {},
  check_order: false,
  verify_mobile: [],
  verify_code: [],
  get_local_areas: [],
  plans: [],
  did_countries: [],
  did_country: {},
  areas: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ERROR_AXIOS:
      return {
        ...state,
        error_axios: action.payload,
      };
    case GET_DID_COUNTRIES:
      return {
        did_countries: action.payload,
      };
    case GET_DID_COUNTRY:
      return {
        ...state,
        did_country: action.payload,
        loading: false,
      };
    case GET_AREAS:
      return {
        ...state,
        areas: action.payload,
      };
    case CHECK_ORDER:
      return {
        ...state,
        check_order: action.payload,
      };
    case UPDATE_ALPHA_ORDER:
      return {
        ...state,
      };
    case GET_PLANS:
      return {
        ...state,
        plans: action.payload,
      };
    case CHECK_EMAIL:
      return {
        ...state,
        check_email: action.payload,
      };
    case REMOVE_ORDER:
      return {
        ...state,
        order_axios: {},
        verify_mobile: [],
      };
    case VERIFY_MOBILE:
      return {
        ...state,
        verify_mobile: action.payload,
      };
    case VERIFY_CODE:
      return {
        ...state,
        verify_code: action.payload,
      };
    case CREATE_ORDER:
      return {
        ...state,
        order_axios: action.payload,
      };
    default:
      return state;
  }
}
