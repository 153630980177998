import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReCAPTCHA from "react-recaptcha";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import axios from "axios";

import scrollIntoView from "scroll-into-view-if-needed";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import FormGroup from '@material-ui/core/FormGroup';


const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class ContactSection extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.checkCaptacha = this.checkCaptacha.bind(this);
    this.state = {
      bronze: true,
      silver: false,
      gold: false,
      twoch: true,
      tench: false,
      thirtych: false,
      phone: "",
      name: "",
      email: "",
      message: "",
      accept: false,
      isVerified: false,
      recaptchaClass: "",
      loading: false,
      classSnackbar: classes.hideSnackbar,
      response: "",
      errors: {
        phone: false,
        name: false,
        email: false,
        message: false,
        accept: false
      }
    };
  }

  checkCaptacha(response) {
    if (response) {
      this.setState({ isVerified: true, recaptchaClass: "" });
    }
  }
  onloadCaptcha() {
    console.log("recaptcha loaded");
  }
  onSubmit = async e => {
    e.preventDefault();

    const { name, phone, email, message, accept, isVerified, loading, bronze, silver, gold, twoch, tench, thirtych } = this.state;
    const { classes } = this.props;

    let job = ""+ (bronze ? " 500 min (bronze) " : "") + (silver ? " 1000 min (silver) " : "") + (gold ? " 1000+ (gold)  " : "") + (twoch ? " 2 channels " : "") + (tench ? " 10 channels " : "") + (thirtych ? " 30 channels " : "");
    let subject = 'Sip Trunk - Callinmarsat';

    let newMsg = message + "\r\n" + job + "\r\n" + phone;

    const newContact = {
      name,
      email,
      message: newMsg,
      subject,
    };
    const login = {
      dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
    };

    // Check For Errors
    if (name === "" || name.length < 3 || name.length > 100) {
      this.setState({ errors: { name: true } });
      const node = document.getElementById("name");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (
      email === "" ||
      !email.match(/^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/) ||
      email.length > 100
    ) {
      this.setState({
        errors: { email: true },
        classSnackbar: classes.errorSnackbar,
        response: "e-mail is required"
      });
      const node = document.getElementById("email");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (
        phone === "" ||
        phone.length < 12 ||
        phone.length > 15 ||
        !phone.match(/^[0-9\b]+$/)
      ) {
        this.setState({ 
          errors: { phone: true },
          classSnackbar: classes.errorSnackbar,
          response: "Pls enter your telephone number!" 
        });
        const node = document.getElementById('phone');
        scrollIntoView(node, { behavior: 'smooth', scrollMode: 'if-needed'  });
        return;
      }

    if (message === "" || message.length < 3 || message.length > 100) {
      this.setState({ errors: { message: true } });
      const node = document.getElementById("message");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (accept === false) {
      this.setState({ errors: { accept: true } });
      const node = document.getElementById("accept");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (!isVerified) {
      this.setState({ recaptchaClass: "recaptcha-error" });
      return;
    }

    if (!loading) {
      this.setState({ loading: true });
      // get the authorization api token
      try {
        const res = await axios.post(
         `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
          login
        );
        const header = {
          Authorization: "Bearer " + res.data.dfa3424
        };
        // sent email

        try {
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/sentemail_cf`,
            newContact,
            { headers: header }
          );
          this.setState({
            loading: false,
            classSnackbar: classes.successSnackbar,
            response: "Message has been sent! Thank you."
          });

          // clear state
          this.setState({
            phone: "",
            name: "",
            email: "",
            message: "",
            accept: false,
            isVerified: false,
            recaptchaClass: "",
            loading: false,
            errors: {
              subject: false,
              name: false,
              email: false,
              message: false,
              accept: false
            }
          });
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
            "",
            { headers: header }
          );
        } catch (e) {
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
            "",
            { headers: header }
          );
          // display error mesage
          this.setState({
            loading: false,
            classSnackbar: classes.errorSnackbar,
            response: "Something went wrong again."
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
          classSnackbar: classes.errorSnackbar,
          response: "Something went wrong again."
        });
      }
    }
  };
  onChange = e => {
    if (e.target.name === "bronze" && e.target.checked === true) {
          this.setState({bronze: true, silver: false, gold: false})
    }
    if (e.target.name === "silver" && e.target.checked === true) {
      this.setState({bronze: false, silver: true, gold: false})
    }
    if (e.target.name === "gold" && e.target.checked === true) {
      this.setState({bronze: false, silver: false, gold: true})
    }
    if (e.target.name === "twoch" && e.target.checked === true) {
      this.setState({twoch: true, tench: false, thirtych: false})
    }
    if (e.target.name === "tench" && e.target.checked === true) {
      this.setState({twoch: false, tench: true, thirtych: false})
    }
    if (e.target.name === "thirtych" && e.target.checked === true) {
      this.setState({twoch: false, tench: false, thirtych: true})
    }
    this.setState(
      e.target.id === "accept"
        ? {
            [e.target.id]: e.target.checked
          }
        : (e.target.value === "" && e.target.id === "phone") ||
          (e.target.value.length < 12 && e.target.id === "phone") ||
          (e.target.value.length > 15 && e.target.id === "phone") ||
          (e.target.id === "phone" && !e.target.value.match(/^[0-9\b]+$/))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "message") ||
          (e.target.id === "message" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "name") ||
          (e.target.id === "name" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : {
          [e.target.id]: e.target.value,
          errors: {
            [e.target.id]: false
          }
        }
    );
  };

  onClick = () =>
    this.setState({
      accept: !this.state.accept
    });

  render() {
    const { classes } = this.props;
    const {
      phone,
      name,
      email,
      errors,
      message,
      accept,
      recaptchaClass,
      loading,
      classSnackbar,
      response,
      bronze,
      silver,
      gold,
	  twoch,
	  tench,
	  thirtych
      
    } = this.state;
    return (
      <GridContainer>
	  	    	  
        <GridItem xs={12} sm={12} md={12}>
          <form onSubmit={this.onSubmit}>
              <GridItem xs={12} sm={12} md={12}>
              <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Please choose your rate for calling Inmarsat :</FormLabel><br></br>
		  <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={bronze} onChange={this.onChange} name="bronze" />}
            label="500 minutes / month ($2.5/min)"
          />
          <FormControlLabel
            control={<Checkbox checked={silver} onChange={this.onChange} name="silver" />}
            label="1000 minutes / month ($2.4/min)"
          />
          <FormControlLabel
            control={<Checkbox checked={gold} onChange={this.onChange} name="gold" />}
            label="1000+ minutes / month ($2.3/min)"
          /><br></br>
         <FormLabel component="legend">How many concurrent calls you would like for your FREE Sip Trunk ?</FormLabel>  
			<br></br>
			<FormControlLabel
            control={<Checkbox checked={twoch} onChange={this.onChange} name="twoch" />}
            label="2 channels"
          />
          <FormControlLabel
            control={<Checkbox checked={tench} onChange={this.onChange} name="tench" />}
            label="10 channels"
          />
          <FormControlLabel
            control={<Checkbox checked={thirtych} onChange={this.onChange} name="thirtych" />}
            label="30 channels"
          />
			
        </FormGroup>
      </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.name ? true : false}
                  required
                  type="text"
                  labelText="Company/Name *"
                  id="name"
                  name="name"
                  value={name}
                  disabled={loading}
                  onChange={this.onChange}
                  onFocus={this.onChange}
                  onBlur={this.onChange}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.email ? true : false}
                  required
                  type="email"
                  labelText="Email *"
                  id="email"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  onFocus={this.onChange}
                  onBlur={this.onChange}
                  disabled={loading}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  error={errors.phone ? true : false}
                  required
                  type="number"
                  labelText="Telephone number *"
                  id="phone"
                  name="phone"
                  value={phone}
                  disabled={loading}
                  onChange={this.onChange}
                  onFocus={this.onChange}
                  onBlur={this.onChange}
                  helperText="Pls enter your telephone number including your country code i.e. +4420123456789"
                  formControlProps={{
                    fullWidth: true
                  }}
                inputProps={{
                startAdornment: (
                    <InputAdornment position="start">+</InputAdornment>
                )
                }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                error={errors.message ? true : false}
                required
                type="text"
                labelText="Notes - Remarks *"
                id="message"
                name="message"
                value={message}
                onChange={this.onChange}
                onFocus={this.onChange}
                onBlur={this.onChange}
                disabled={loading}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />
              </GridItem>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        required
                        id="accept"
                        name="accept"
                        checked={accept}
                        onClick={this.onClick}
                        onChange={this.onChange}
                        disabled={loading}
                      />
                    }
                    label={
                      <Typography className={classes.labelCheckbox}>
                        I confirm that I have read the clause regarding the processing of personal data and I agree to the processing of the data by CALLINMARSAT for the purpose necessary to handle the query or improve the service. I have been informed that the provision of data is voluntary, I have the right to inspect the content of the data and requesting their correction or deletion.{" "}
                        <a href="/privacy">Privacy Policy</a>
                      </Typography>
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div className={recaptchaClass}>
                  <ReCAPTCHA
                    size="normal"
                    render="explicit"
                    sitekey="6LcoDlMiAAAAAGH0XbbL5yXmo3WwAv3y7QTfLtTU"
                    onloadCallback={this.onloadCaptcha}
                    verifyCallback={this.checkCaptacha}
                  />
                </div>
              </GridItem>

              <GridContainer className={classes.buttonContainer}>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  className={classes.textCenter}
                >
                  <Button
                    type="submit"
                    color="primary"
                    disabled={loading}
                    variant="contained"
                  >
                    SEND
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </GridItem>
              </GridContainer>
          </form>
          <SnackbarContent
            className={classSnackbar}
            aria-describedby="client-snackbar"
            message={response}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

ContactSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(workStyle)(ContactSection);