import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.jsx";

import Footer from "components/Footer/Footer.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import LinkIcon from '@material-ui/icons/Link';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

class Getstarted extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            {"LOCAL NUMBER SERVICE"}
          </h2>
          <div className={classes.container}>
            <h2 style={{ color: "#07a8b3" }}>
              <b>HOW TO GET STARTED</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            <ol>
              <li> <b><a href="/buynow">Buy now</a> and create an account.</b> It is fast and ready within minutes.</li>
              <li> During sign up , you will <b>select local number, airtime, and set your SAT number</b> ( destination number ).</li>
              <li> <b>Purchase</b> one of our <b>bundles or add funds </b>for pay as you go.</li>
              <li> Upon successful registration the <b>service is ready for use</b> immediately.</li>
              <li> You will also receive email with your account username and password.</li>
              <li> From your account , you can <b>see all your calls, change your plan, add funds or change your destination number.</b></li>
            </ol>
            <br /><br />
            That's it! <b>Everytime you call your local number , we will connect you to your destination number.</b> ( The inmarsat number or any number you want to reach ).
            <br /><br />
            Although we offer this service to call expensive Inmarsat numbers, this service can be used to call any number world wide. All you have to do is to change the destination number from your portal. <b> Check our rates for calling other numbers.</b>
            <br /><br />
            </Typography>
            <Button
              style={{fontSize: "18px"}}
                fullWidth
                color="secondary"
                href="/rates"
                startIcon={<LinkIcon />}
              >
                CHECK OUR RATES
            </Button>
            <Button
              style={{fontSize: "18px"}}
                fullWidth
                color="primary"
                href="/buynow"
                startIcon={<BusinessCenterIcon />}
              >
                BUY NOW
            </Button>
          </div>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

Getstarted.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(Getstarted);