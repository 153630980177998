import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

// core components
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Footer from "components/Footer/Footer.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "./Sections/VisaSection.jsx";
import CheckoutSection from "./Sections/CheckoutSection.jsx";
import CookieConsent from "react-cookie-consent";

import { connect } from "react-redux";
import { checkOrder, getDIDCountry, getAreas, getPlans } from "actions/retailActions";

import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";


class SignUpPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    this.state = { loading: false}
  }

  async UNSAFE_componentWillMount() {
    this.setState({loading: true});
    const { redirect, order, country_id } = this.props.match.params;

    await this.props.getAreas(country_id);
    await this.props.getDIDCountry(country_id);
    await this.props.getPlans();

    if ((order !== undefined && redirect === 'cancel') || (order !== undefined && redirect === 'sucess')) {
      await this.props.checkOrder(order);
    }
    this.setState({loading: false});
  }

  render() {
    const {  redirect, order, area_code, area_name  } = this.props.match.params;
    const { classes, check_order, country, areas, plans, ...rest } = this.props;
    let area = areas.length > 0 ? areas.filter( a => a.code === area_code && a.name === area_name) : null;

    const { loading } = this.state;
    return (
      <div>
        <Header
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
				
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: '15px'
            }}
          >
            Checkout
          </h1>
		  
          <div className={classes.container}>
		  
            {loading ? (
              <GridContainer justify="center">
                <CircularProgress size={50} />
              </GridContainer>
            ) : (
              <CheckoutSection check_order={check_order} redirect={redirect} order={order} country={ country } area={area} plans={plans}/>
            )}
          </div>
          <VisaSection />
        </div>
        <Footer />
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

SignUpPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  check_order: state.retail_reducer.check_order,
  country: state.retail_reducer.did_country,
  areas: state.retail_reducer.areas,
  plans: state.retail_reducer.plans
});


export default connect(
  mapStateToProps,
  { checkOrder, getDIDCountry, getAreas, getPlans }
)(withStyles(landingPageStyle)(SignUpPage));
