import { title } from "assets/jss/material-kit-react.jsx";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

const workStyle = {
  section: {
    padding: "10px 0"
  },
  title: {
    ...title,
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  descriptionTitle: {
    color: "#000",
    textAlign: "center"
  },
  description: {
    color: "#000",
    fontWeight: "400"
  },
  textCenter: {
    textAlign: "center"
  },
  textEnd: {
    textAlign: "end"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  labelCheckbox: {
    fontSize: "12px"
  },
  buttonContainer: {
    display: "contents"
  },
  iconBig: {
    fontSize: "26px"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  successSnackbar: {
    backgroundColor: green[600]
  },
  errorSnackbar: {
    backgroundColor: red[600]
  },
  hideSnackbar: {
    display: "none"
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginLeft: "8px"
  }
};

export default workStyle;
