import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import GridContainer from "components/Grid/GridContainer.jsx";
import PlansTable from "../../Sections/PlansTable.jsx";
import Slider from "@material-ui/core/Slider";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import { connect } from "react-redux";
import { createOrder } from "actions/retailActions";

class PlansForm extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes, phonemove, plans, products, top_up } = props;
    this.state = {
      phonemove: phonemove,
      classSnackbar: classes.hideSnackbar,
      response: "",
      loading: false,
      top_up: top_up,
      initTopUP: parseInt(parseFloat(top_up).toFixed()),
      plans: plans,
      topup: [
        {
          value: 0.0,
          label: "",
        },
        {
          value: 10.0,
          label: "10 $",
        },
        {
          value: 50.0,
          label: "50 $",
        },
        {
          value: 100.0,
          label: "100 $",
        },
        {
          value: 300.0,
          label: "300 $",
        },
        {
          value: 500.0,
          label: "500 $",
        },
      ],
      products: products,
    };
  }
  selectPlan = async (id_plan, name, period_cost, selected) => {
    if (selected) {
      let newState = this.state.products.concat({
        id: id_plan,
        name: name,
        price: period_cost,
      });
      let newPlans = this.state.plans.concat(id_plan);
      await this.setState({ plans: newPlans, products: newState });
    } else {
      for (let i = 0; i < this.state.products.length; i++) {
        if (this.state.products[i].id === id_plan) {
          let newState = this.state.products.filter(
            (product) => product.id !== id_plan
          );
          let newPlans = this.state.plans.filter((plan) => plan !== id_plan);
          this.setState({ plans: newPlans, products: newState });
        }
      }
    }
  };
  onChangeCommitted = (e, value) => {
    let newState = Object.assign({}, this.state);
    newState.products[1].price = value.toFixed(2);
    this.setState(newState);
    this.setState({ top_up: value.toFixed(2) });
  };
  onClick = async (e) => {
    this.setState({ loading: true });
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      address,
      accept,
      city,
      phonemobile,
    } = this.props;

    const { phonemove, products, plans, top_up } = this.state;

    this.setState({ loading: false });

    this.props.handleBack(
      firstName,
      lastName,
      email,
      phonemobile,
      address,
      accept,
      phonemove,
      city,
      products,
      plans,
      top_up
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      accept,
      phonemove,
      address,
      city,
      classes,
      country,
      area,
      verify_mobile,
      phonemobile,
    } = this.props;
    this.setState({ classSnackbar: classes.hideSnackbar });
    const { products, top_up, plans, loading } = this.state;

    // handle next
    if (top_up > 0 || plans.length !== 0) {
      //create order
      if (!loading) {
        this.setState({ loading: true });
        // sent axios loading
        console.log(verify_mobile);
        await this.props.createOrder(
          firstName,
          lastName,
          email,
          address,
          phonemove,
          city,
          plans,
          top_up,
          country,
          area,
          verify_mobile.order_id
        );
        if (Object.values(this.props.error_axios).length !== 0) {
          // display error mesage
          this.setState({
            loading: false,
            classSnackbar: classes.errorSnackbar,
            response: "Something went wrong again. Please try again.",
          });
          this.setState({ loading: false });
          return;
        }
      }
      this.setState({ loading: false });

      this.props.handleNext(
        firstName,
        lastName,
        email,
        phonemobile,
        address,
        accept,
        phonemove,
        city,
        products,
        verify_mobile,
        plans,
        top_up,
        this.props.order_axios
      );
    } else {
      this.setState({
        classSnackbar: classes.errorSnackbar,
        response: "Please select Plan or TOP UP",
      });
    }
  };
  render() {
    const { activeStep, classes, plansInit } = this.props;
    const {
      loading,
      classSnackbar,
      response,
      topup,
      initTopUP,
      plans,
    } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h6" gutterBottom>
            Please select one of the available plans or TOP UP your account for
            pay as you go.
          </Typography>
          <Typography variant="h6" color="primary">
            Plans
          </Typography>
          <Grid item xs={12} sm={12}>
            <GridContainer spacing={2}>
              {plansInit.map((plan) => (
                <PlansTable
                  disabled={loading}
                  plan={plan}
                  key={plan.id_plan}
                  selected={
                    plans.filter((p) => p === plan.id_plan).toString() ===
                    plan.id_plan.toString()
                      ? true
                      : false
                  }
                  selectPlan={this.selectPlan}
                />
              ))}
            </GridContainer>
          </Grid>
          <br /> <br />
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" color="primary">
              TOP UP
            </Typography>
            <Typography variant="body1">
              Select the amount of funds you want to add to your account.
            </Typography>
            <Slider
              disabled={loading}
              onChangeCommitted={this.onChangeCommitted}
              defaultValue={initTopUP}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={10}
              marks={topup}
              min={0}
              max={500}
            />
          </Grid>
          <SnackbarContent
            className={classSnackbar}
            aria-describedby="client-snackbar"
            message={response}
          />
          <GridContainer className={classes.buttons}>
            <GridItem xs={12} sm={12} md={12} className={classes.textEnd}>
              {activeStep !== 0 ? (
                <Button
                  onClick={this.onClick}
                  className={classes.button}
                  disabled={loading}
                >
                  BACK
                </Button>
              ) : null}

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
              >
                {"NEXT"}
              </Button>
              {loading ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : null}
            </GridItem>
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}

PlansForm.propTypes = {
  classes: PropTypes.object,
  activeStep: PropTypes.number,
  steps: PropTypes.array,
};

const mapStateToProps = (state) => ({
  order_axios: state.retail_reducer.order_axios,
  error_axios: state.retail_reducer.error_axios,
});

export default connect(
  mapStateToProps,
  { createOrder }
)(withStyles(workStyle)(PlansForm));
