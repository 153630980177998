import {
  ERROR_AXIOS,
  CHECK_EMAIL,
  GET_LOCAL_ARES,
  VERIFY_MOBILE,
  REMOVE_ORDER,
  VERIFY_CODE,
  CREATE_ORDER,
  GET_PLANS,
  UPDATE_ALPHA_ORDER,
  CHECK_ORDER,
  GET_DID_COUNTRIES,
  GET_AREAS,
  GET_DID_COUNTRY,
} from "./types";
import axios from "axios";

export const getDIDCountries = () => async (dispatch) => {
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_cf_countries`,
    ""
  );
  dispatch({
    type: GET_DID_COUNTRIES,
    payload: res.data,
  });
};

export const getDIDCountry = (id) => async (dispatch) => {
  const content = {
    country_id: id,
  };
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_cf_country`,
    content
  );
  dispatch({
    type: GET_DID_COUNTRY,
    payload: res.data,
  });
};

export const getAreas = (id) => async (dispatch) => {
  const content = {
    country_id: id,
  };
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_cf_area`,
    content
  );
  dispatch({
    type: GET_AREAS,
    payload: res.data,
  });
};

export const getPlans = () => async (dispatch) => {
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_cf_plans`,
    ""
  );
  dispatch({
    type: GET_PLANS,
    payload: res.data,
  });
};

export const checkEmail = (email) => async (dispatch) => {
  const check = {
    email: email,
  };
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/check_email_react`,
    check
  );
  dispatch({
    type: CHECK_EMAIL,
    payload: res.data,
  });
};
export const getlocalAreas = () => async (dispatch) => {
  const res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/get_local_areas`,
    ""
  );
  dispatch({
    type: GET_LOCAL_ARES,
    payload: res.data,
  });
};
export const verifyCode = (order_id, token, code) => async (dispatch) => {
  const content = {
    id_order: order_id,
    dsfsafw352: token,
    code: code,
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh",
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424,
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/gfsd56hgf45646fgdcf`,
      content,
      { headers: header }
    );
    dispatch({
      type: VERIFY_CODE,
      payload: res.data,
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const verifyMobile = (
  firstName,
  lastName,
  email,
  phonemobile,
  phonemove,
  address,
  city
) => async (dispatch) => {
  const content = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    phonemobile: phonemobile,
    phonemove: phonemove,
    address: address,
    city: city,
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh",
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424,
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/verify_mobile_cf`,
      content,
      { headers: header }
    );
    dispatch({
      type: VERIFY_MOBILE,
      payload: res.data,
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const removeOrder = (order_id, token) => async (dispatch) => {
  const content = {
    id_order: order_id,
    dsfsafw352: token,
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh",
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424,
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/cfdfasd4234FDSd23`,
      content,
      { headers: header }
    );
    dispatch({
      type: REMOVE_ORDER,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ERROR_AXIOS,
      payload: e,
    });
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};

export const checkOrder = (order) => async (dispatch) => {
  const content = {
    order_id: order,
  };
  try {
    const res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/check_cf_order`,
      content
    );
    dispatch({
      type: CHECK_ORDER,
      payload: res.data,
    });
  } catch (e) {
    return e;
  }
};
export const updateDirectOrder = (
  plans,
  top_up,
  verify_mobile,
  paymentMethod
) => async (dispatch) => {
  const content = {
    plans: plans,
    top_up: top_up,
    order_id: verify_mobile.order_id,
    dsfsafw352: verify_mobile.dsfsafw352,
    paymentMethod: paymentMethod,
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh",
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424,
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsdgfds6345gsx`,
      content,
      { headers: header }
    );
    dispatch({
      type: UPDATE_ALPHA_ORDER,
      payload: res.data,
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const updateAlphaOrder = (
  plans,
  top_up,
  verify_mobile,
  paymentMethod
) => async (dispatch) => {
  const content = {
    plans: plans,
    top_up: top_up,
    order_id: verify_mobile.order_id,
    dsfsafw352: verify_mobile.dsfsafw352,
    paymentMethod: paymentMethod,
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh",
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424,
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/fsdgfds6345gs`,
      content,
      { headers: header }
    );
    dispatch({
      type: UPDATE_ALPHA_ORDER,
      payload: res.data,
    });
  } catch (e) {
    await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
      "",
      { headers: header }
    );
    return e;
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
export const createOrder = (
  firstName,
  lastName,
  email,
  address,
  phonemove,
  city,
  plans,
  top_up,
  country,
  area,
  order_id
) => async (dispatch) => {
  const content = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    address: address,
    order_id: order_id,
    phonemove: phonemove,
    city: city,
    plans: plans,
    top_up: top_up,
    country_id: country.id,
    country_name: country.country_name,
    area_code: area[0].code,
    area_name: area[0].name,
    area_local_id: area[0].local_id ? area[0].local_id : area[0].localAreaId,
  };
  const login = {
    dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh",
  };
  let res = await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
    login
  );
  const header = {
    Authorization: "Bearer " + res.data.dfa3424,
  };
  try {
    res = await axios.post(
      `https://sip1.easyvoipcall.com/pbxres/api/cffsd234hgfh56PSX7mjhkj`,
      content,
      { headers: header }
    );
    dispatch({
      type: CREATE_ORDER,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: ERROR_AXIOS,
      payload: e,
    });
  }
  await axios.post(
    `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
    "",
    { headers: header }
  );
};
