import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import Footer from "components/Footer/Footer.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import WorkSection from "./Sections/WorkSection.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

class Contact extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            Contact us
          </h1>
          <div className={classes.container}>
          <WorkSection />
          </div>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

Contact.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(Contact);
