import SipTrunkPage from "./views/SipTrunkPage";
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import HomePage from "./views/HomePage";
import SolutionsPage from "./views/SolutionsPage";
import Privacy from "./views/PrivacyPage";
import LocalNumbersPage from "./views/LocalNumbersPage";
import ContactPage from "./views/ContactPage";
import NotFound from "./views/NotFound";
import BuyNowPage from "./views/BuyNowPage";
import Rates from "./views/RatesPage";
import DIDs from "./views/DIDsPage";
import Areas from "./views/AreasPage";


import "assets/scss/material-kit-react.scss?v=1.7.0";

import { Provider } from "react-redux";
import store from "store";

var hist = createBrowserHistory();

function App() {
  return (
    <Provider store={store}>
      <Router history={hist}>
        <div className="App">
          <Switch>
		    <Route exact path="/siptrunk" component={SipTrunkPage} />
            <Route exact path="/" component={HomePage} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/rates" component={Rates} />
            <Route exact path="/buynow" component={DIDs} />
            <Route exact path="/didnumbers" component={DIDs} />
            <Route exact path="/solutions" component={SolutionsPage} />
			<Route exact path="/signup/:country_id/:area_code/:area_name" component={BuyNowPage} />
            <Route exact path="/:redirect/:order/signup/:country_id/:area_code/:area_name" component={BuyNowPage}/>
            <Route exact path="/localnumbers" component={LocalNumbersPage} />
			<Route exact path="/contact" component={ContactPage} />
            <Route exact path="/didareas/:id" component={Areas} /> 
			<Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
