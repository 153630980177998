import React, { useState } from "react";

import Card from '@material-ui/core/Card';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import cardStyle from "assets/jss/material-kit-react/components/cardPricing.jsx";

function CardPricing({ ...props }) {
    const { classes, children, ...rest  } = props;
    const [hovering, setHover] = useState(false);
  return (
    <Card className={ hovering ? classes.cardHover : classes.card} {...rest }
    onMouseEnter={() => setHover(true)}
    onMouseLeave={() => setHover(false)}
    >
      {children}
    </Card>
  )
}

CardPricing.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node
  };

export default withStyles(cardStyle)(CardPricing);