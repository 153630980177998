import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Typography from "@material-ui/core/Typography";
//import Button from "components/CustomButtons/Button.jsx";

import Footer from "components/Footer/Footer.jsx";

import HeaderLinks from "components/Header/HeaderLinks.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
//import LinkIcon from '@material-ui/icons/Link';
//import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

import ContactSection from "./Sections/ContactSection.jsx";

import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

class Getstarted extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            {"IP INTERCONNECTION OR SIP TRUNK SERVICE"}
          </h2>
          <div className={classes.container}>
            <h2 style={{ color: "#07a8b3" }}>
              
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
			<b>Direct interconnection</b> to our network, multiple Tier 1 providers <b>offering redundancy and high call completion on all calls</b>.A perfect and easy solution for telecom operators or shipping companies looking for an economic good quality route to call inmarsat and other satellite destinations. Contact us and send us your request. We have the solution for you.
            
			<h2 id="did"
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "10px"
            }}
          >
           
			
          </h2>
		  <br></br>            
            <center>Although we offer this service to call expensive Inmarsat numbers, this service can be used to call <b>ANY</b> number world wide.<p></p> <b><br></br> Check our <a href="/rates">International Rates</a> for calling other numbers. </b></center>
            <br></br>
            <h2
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            {"Build your Own FREE SIP Trunk"}
          </h2>
		  		  	
			</Typography>
                      			
            <ContactSection/>
          </div>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

Getstarted.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(Getstarted);