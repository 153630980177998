import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import ReCAPTCHA from "react-recaptcha";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import axios from "axios";
import scrollIntoView from "scroll-into-view-if-needed";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

class WorkSection extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.checkCaptacha = this.checkCaptacha.bind(this);
    this.state = {
      subject: "",
      name: "",
      email: "",
      message: "",
      isVerified: false,
      recaptchaClass: "",
      loading: false,
      classSnackbar: classes.hideSnackbar,
      response: "",
      errors: {
        subject: false,
        name: false,
        email: false,
        message: false,
      }
    };
  }

  checkCaptacha(response) {
    if (response) {
      this.setState({ isVerified: true, recaptchaClass: "" });
    }
  }
  onloadCaptcha() {
    console.log("recaptcha loaded");
  }
  onSubmit = async e => {
    e.preventDefault();

    const { name, subject, email, message, isVerified, loading } = this.state;
    const { classes } = this.props;
    const newContact = {
      name,
      email,
      message,
      subject
    };
    const login = {
      dfa3424: "daDAS!@DFSGHFGJ(*)hGdffser4324765~hfgh"
    };

    // Check For Errors
    if (name === "" || name.length < 3 || name.length > 100) {
      this.setState({ errors: { name: true } });
      const node = document.getElementById("name");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (
      email === "" ||
      !email.match(/^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/) ||
      email.length > 100
    ) {
      this.setState({
        errors: { email: true },
        classSnackbar: classes.errorSnackbar,
        response: "Email required"
      });
      const node = document.getElementById("email");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (subject === "" || subject.length < 3 || subject.length > 100) {
      this.setState({ errors: { subject: true } });
      const node = document.getElementById("subject");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }

    if (message === "" || message.length < 3 || message.length > 100) {
      this.setState({ errors: { message: true } });
      const node = document.getElementById("message");
      scrollIntoView(node, { behavior: "smooth", scrollMode: "if-needed" });
      return;
    }
    if (!isVerified) {
      this.setState({ recaptchaClass: "recaptcha-error" });
      return;
    }

    if (!loading) {
      this.setState({ loading: true });
      // get the authorization api token
      try {
        const res = await axios.post(
          `https://sip1.easyvoipcall.com/pbxres/api/fsdffgdg670dbmfsfv90cxxiy`,
          login
        );
        const header = {
          Authorization: "Bearer " + res.data.dfa3424
        };
        // sent email

        try {
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/sentemail_cf`,
            newContact,
            { headers: header }
          );
          this.setState({
            loading: false,
            classSnackbar: classes.successSnackbar,
            response: "Thank you your message has been sent."
          });

          // clear state
          this.setState({
            subject: "",
            name: "",
            email: "",
            message: "",
            isVerified: false,
            recaptchaClass: "",
            loading: false,
            errors: {
              subject: false,
              name: false,
              email: false,
              message: false,
            }
          });
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
            "",
            { headers: header }
          );
        } catch (e) {
          await axios.post(
            `https://sip1.easyvoipcall.com/pbxres/api/jfg5345hfgh4545b4234234gv`,
            "",
            { headers: header }
          );
          // display error mesage
          this.setState({
            loading: false,
            classSnackbar: classes.errorSnackbar,
            response: "Something went wrong please try again."
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
          classSnackbar: classes.errorSnackbar,
          response: "Something went wrong please try again."
        });
      }
    }
  };
  onChange = e => {
    this.setState(
        (e.target.value === "" && e.target.id === "subject") ||
          (e.target.id === "subject" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "message") ||
          (e.target.id === "message" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "name") ||
          (e.target.id === "name" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : {
          [e.target.id]: e.target.value,
          errors: {
            [e.target.id]: false
          }
        }
    );
  };
  onFocus = e => {
    this.setState(
      (e.target.value === "" && e.target.id === "message") ||
        (e.target.id === "message" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "subject") ||
          (e.target.id === "subject" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "name") ||
          (e.target.id === "name" && e.target.value.length < 3)
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : (e.target.value === "" && e.target.id === "email") ||
          (e.target.id === "email" &&
            !e.target.value.match(
              /^[a-z._A-Z0-9]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/
            ))
        ? {
            [e.target.id]: e.target.value,
            errors: {
              [e.target.id]: true
            }
          }
        : {
            errors: {
              subject: false,
              name: false,
              email: false,
              message: false
            }
          }
    );
  };

  render() {
    const { classes } = this.props;
    const {
      subject,
      name,
      email,
      errors,
      message,
      recaptchaClass,
      loading,
      classSnackbar,
      response
    } = this.state;
    return (
      <GridContainer spacing={5}>
	    justify="center"
          <GridItem xs={5}>
		  <h2 className={classes.description}>Callinmarsat <br/>EasyVoipCall Inc.</h2>
          <h4 className={classes.description}>Canadian corporation since 2005 with local offices in Canada and Europe.</h4>
          <List>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-address-card" />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="Suite 150
                283 Danforth Ave.
                Torondo, Ontario M4K 3T9"
                secondary="Canada"
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-phone" />
              </ListItemIcon>
              <a href="tel:0018448673447">
                <ListItemText
                  className={classes.description}
                  primary="+1-844-867-3447"
                ></ListItemText>
              </a>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-fax" />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="+1-855-652-8286"
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-envelope" />
              </ListItemIcon>
              <a href="mailto:info@callinmarsat.com">
                <ListItemText
                  className={classes.description}
                  primary="info@callinmarsat.com"
                ></ListItemText>
              </a>
            </ListItem>
          </List>
		  
        </GridItem>
		<GridItem xs={5}>
		  <h2 className={classes.description}>Hellasfon Network S.A. (Europe Offices)</h2>
          <h4 className={classes.description}>Global service provider for Greece since 1999.</h4>
          <List>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-address-card" />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="Idomeneos 26 Street,
                Heraklion - Crete , Postal Code 71 202"
                secondary="Greece"
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-phone" />
              </ListItemIcon>
              <a href="tel:00302811300307">
                <ListItemText
                  className={classes.description}
                  primary="+30-2811-300-307"
                ></ListItemText>
              </a>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-fax" />
              </ListItemIcon>
              <ListItemText
                className={classes.description}
                primary="+30-2811-300-308"
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon className={classes.iconBig}>
                <i className="fas fa-envelope" />
              </ListItemIcon>
              <a href="mailto:info@hellasfon.com">
                <ListItemText
                  className={classes.description}
                  primary="info@hellasfon.com"
                ></ListItemText>
              </a>
            </ListItem>
          </List>
		  
        </GridItem>
      </GridContainer>  
      
    );
  }
}

WorkSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(workStyle)(WorkSection);
