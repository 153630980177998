/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.jsx";

import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";


function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link to="/" color="transparent" className={classes.navLink}>
          HOME
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/localnumbers" color="transparent" className={classes.navLink}>
          LOCAL NUMBERS
        </Link>
      </ListItem>
	  <ListItem className={classes.listItem}>
        <Link to="/siptrunk" color="transparent" className={classes.navLink}>
          SIPTRUNK
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/buynow" color="transparent" className={classes.navLink}>
          Available Numbers
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/rates" color="transparent" className={classes.navLink}>
          RATES
        </Link>
      </ListItem>
	  <ListItem className={classes.listItem}>
        <Link to="/solutions" color="transparent" className={classes.navLink}>
          SOLUTIONS
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
      <CustomDropdown
          noLiPadding
          buttonText="MY ACCOUNT"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          dropdownList={[
            <Link
              to=""
              className={classes.dropdownLink}
              onClick={() => {window.location.href = "https://myaccount.callinmarsat.com"}}
            >
              Call Forward
            </Link>,
            <Link
              to=""
              onClick={() => {window.location.href = "https://myaccount.callinmarsat.com/vup/"}}
              className={classes.dropdownLink}
            >
              SIP Trunk
            </Link>,
          ]}
        />
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);