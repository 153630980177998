import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import CookieConsent from "react-cookie-consent";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";


class NotFound extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
         color="white"
         logoUrl="/img/callinmarsat_logo.png"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax
          filter
          image={require("assets/img/Callinmarsat.jpg")}
        >
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <h1 className={classes.title}>
                  404 The page does not exist
                </h1>
                <h3 className={classes.titleText}>
                Call <strong>Inmarsat</strong>, provides economic solutions to call <strong>Inmarsat</strong> numbers and satellite phones by calling a <strong>local number</strong>. Whether you are an individual or a business,  we specialize in maritime and shore to ship communications.			
                </h3>
                <br />
                <Button
                  color="primary"
                  size="lg"
                  href="/contact"
                  rel="noopener noreferrer"
                >
                  Contact
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <Footer />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

NotFound.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(NotFound);
