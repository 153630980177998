/*eslint-disable*/
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "react-router-dom";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderTopLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.listTop}>
      <ListItem className={classes.listTopItem}>
          <Button href="/" color="transparent" className={classes.navTopLink}>
          Ιδιώτες
          </Button>
      </ListItem>
      <ListItem className={classes.listTopItem}>
        <CustomDropdown
          noLiPadding
          buttonText="ΕΠΙΧΕΙΡΗΣΗ-ΕΤΑΙΡΙΕΣ"
          buttonProps={{
            className: classes.navTopLink,
            color: "transparent"
          }}
          dropdownList={[
            <Link to="/siptrunk" className={classes.dropdownLink}>
              Γραμμές τηλεφώνου
            </Link>,
             <a href="/business" target="" className={classes.dropdownLink}>
             Τηλεφωνικά κέντρα
            </a>
          ]}
        />
      </ListItem>
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderTopLinks);
