export const GET_PLANS = "GET_PLANS";
export const UPDATE_ALPHA_ORDER = "UPDATE_ALPHA_ORDER";

export const ERROR_AXIOS = "ERROR_AXIOS";
export const CHECK_EMAIL = "CHECK_EMAIL";
export const GET_LOCAL_ARES = "GET_LOCAL_ARES";
export const VERIFY_MOBILE = "VERIFY_MOBILE";
export const VERIFY_CODE = "VERIFY_CODE";
export const CHECK_ORDER = "CHECK_ORDER";
export const GET_DID_COUNTRIES = "GET_DID_COUNTRIES";
export const GET_AREAS = "GET_AREAS";
export const GET_DID_COUNTRY = "GET_DID_COUNTRY";
export const CREATE_ORDER = "CREATE_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";
