import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import Button from "components/CustomButtons/Button.jsx";
import CookieConsent from "react-cookie-consent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "./Sections/VisaSection.jsx";
import WorkSection from "./Sections/WorkSection.jsx";

import Grid from "@material-ui/core/Grid";

import TuneIcon from "@material-ui/icons/Tune";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import CloudDoneTwoToneIcon from "@material-ui/icons/CloudDoneTwoTone";
import ListAltTwoToneIcon from "@material-ui/icons/ListAltTwoTone";
import PanToolTwoToneIcon from "@material-ui/icons/PanToolTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import VpnLockTwoToneIcon from "@material-ui/icons/VpnLockTwoTone";
import FindInPageTwoToneIcon from "@material-ui/icons/FindInPageTwoTone";
import ForumTwoToneIcon from "@material-ui/icons/ForumTwoTone";
import PhoneAndroidTwoToneIcon from "@material-ui/icons/PhoneAndroidTwoTone";
import BusinessCenterTwoToneIcon from "@material-ui/icons/BusinessCenterTwoTone";
import CallSplitTwoToneIcon from "@material-ui/icons/CallSplitTwoTone";
import OfflineBoltTwoToneIcon from "@material-ui/icons/OfflineBoltTwoTone";
import VerifiedUserTwoToneIcon from "@material-ui/icons/VerifiedUserTwoTone";
import LocalAtmTwoToneIcon from "@material-ui/icons/LocalAtmTwoTone";
import DesktopWindowsTwoToneIcon from "@material-ui/icons/DesktopWindowsTwoTone";
import PowerTwoToneIcon from "@material-ui/icons/PowerTwoTone";
import PublicTwoToneIcon from "@material-ui/icons/PublicTwoTone";
import MapTwoToneIcon from "@material-ui/icons/MapTwoTone";
import LiveTvTwoToneIcon from "@material-ui/icons/LiveTvTwoTone";
import FavoriteTwoToneIcon from "@material-ui/icons/FavoriteTwoTone";

class HomePage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax filter image={require("assets/img/Callinmarsat.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={8}>
                <h1 className={classes.title}>Call Inmarsat</h1>
                <h3 className={classes.titleText}>
                  Economic solutions to
                  call inmarsat and other international destinations.
				</h3>
				<h4 className={classes.titleText}>
				  <b>LOCAL NUMBER</b> - Call Inmarsat by calling a LOCAL number in your area. <br/>
				  Sign up and save up to 75% on your calling fees.
				</h4>
                <Button
                  style={{ fontSize: "15px" }}
                  color="warning"
                  size="md"
                  href="/buynow"
                  rel="noopener noreferrer"
                  startIcon={<BusinessCenterIcon />}
                >
                  Buy Now !
                </Button>
				<h4 className={classes.titleText}>
                  <b>SIP TRUNK </b>– HD quality, high connectivity, 
				  and high call completion on all calls. <br/>
				  Register your free sip trunk today.
				</h4>
                <Button
                  style={{ fontSize: "15px" }}
                  color="warning"
                  size="md"
                  href="/siptrunk"
                  rel="noopener noreferrer"
                  startIcon={<BusinessCenterIcon />}
                >
                  Get Free SIP Trunk!
                </Button>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              marginTop: "0px",
              marginBottom: "30px",
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
            }}
          >
            Call LOCAL and talk GLOBAL
          </h1>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 500 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <VerifiedUserTwoToneIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      A simple way to call a Satellite phone
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Remembering the international access codes and dialing a
                      satellite number can be confusing and frustrating. That’s
                      where we come in. By Dialling any one of our local numbers
                      you can reach your satellite number with a local call.
                      Avoid hefty international rate charges from your phone
                      company by dialing locally. Easier calling and bigger
                      savings. Its a win solution for everyone.
                      <br />
                      <br />
                      Monitor and control your costs with Call Inmarsat. We can
                      help.
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 500 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <LocalAtmTwoToneIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      SAVE! SAVE!
                    </Typography>
                    <br />
                    <Typography variant="body1" color="textSecondary">
                      Call Inmarsat is directly connected to all the Satellite
                      providers, providing exceptional quality and a stable low
                      rate without middlemen. Easy calling features, user
                      friendly platform and cost effective tools . In simple
                      terms, call local and talk global, as opposed to calling
                      long distance satellite calls.
                      <br />
                      <br />
                      Avoid hefty international charges from your phone company.
                      Stop all unauthorized calls with pin based access codes
                      and save money by using our direct global satellite
                      network.
                      <br />
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 500 }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <DesktopWindowsTwoToneIcon
                      style={{ color: "#07a8b3", fontSize: "65px" }}
                    />
                    <Typography variant="h5" style={{ color: "#0c696d" }}>
                      Solutions for Everyone
                    </Typography>
                    <br />
                    <ul>
                      <Typography variant="body1" color="textSecondary">
                        Calling Inmarsat? We have a solution for you.
                        <li>
                          <b>Carrier interconnection services</b> with direct
                          routes for Inmarsat and other SAT destinations.
                        </li>
                        <li>
                          <b>EVCOFFICE Cloud PBX</b> - Call Inmarsat by dialling
                          an extension.
                        </li>
                        <li>
                          <b>EVCMOBI app</b> - CALL Inmarsat at the lowest rates
                          using EVCMOBI.
                        </li>
                        <li>
                          <b>360Travelsim / 360IOT sim</b> - Global coverage,
                          200+ countries.
                        </li>
                        <li>
                          <b>Sip interconnection - SIPTRUNK</b> with 1,10,30 or
                          more concurent calls.
                        </li>
                      </Typography>
                    </ul>
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <GridContainer
            justify="center"
            style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }}
          >
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="img/money-card-business-credit-card-50987.jpeg"
                alt="call inmarsat"
                title="call inmarsat"
              />
            </GridItem>
            <GridItem cs={12} sm={12} md={6} style={{ background: "#20d0d6" }}>
              <h2 style={{ color: "#fff" }}>
                <b>Pricing</b>
              </h2>
              <Typography variant="h5" style={{ color: "#fff" }}>
                Registering a local number with Call Inmarsat can save you up to
                75% on per minute rates when calling a satellite phone.
              </Typography>
              <h2 style={{ color: "#fff" }}>
                <b>Superior quality</b>
              </h2>
              <Typography variant="h5" style={{ color: "#fff" }}>
                HD quality over our direct interconection with Satellite
                providers. No commitments! ONLY SAVINGS!
              </Typography>
              <h2 style={{ color: "#fff" }}>
                <b>NO COMMITMENTS</b>
              </h2>
              <Typography variant="h5" style={{ color: "#fff" }}>
                Purchase your number for the period you want and renew it when
                it expires. We proudly stand on our Quality alone. There are no
                commitments! Airtime is billed per minute and is deducted from
                your balance. We are proud to say that we have the lowest per
                minute billing for satellite phones. see our rates and compare.
              </Typography>
            </GridItem>
          </GridContainer>
          <div className={classes.container}>
            <h2 style={{ color: "#07a8b3", textAlign: "center" }}>
              <b>WHY USE CALL INMARSAT?</b>
            </h2>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 560 }}>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    title={
                      <PowerTwoToneIcon
                        style={{ color: "#07a8b3", fontSize: "65px" }}
                      />
                    }
                  />
                  <CardMedia
                    component="img"
                    image="/img/pexels-photo-87236.jpeg"
                    title="call inmarsat"
                    alt="call inmarsat"
                  />
                  <Typography
                    variant="h5"
                    style={{
                      color: "#fff",
                      background: "#07a5a3",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    Oil & Gas
                  </Typography>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography variant="body1" color="textSecondary">
                      We provide secure and dependable communication to business
                      and individuals world wide. Offering solutions to
                      communicate with all those hard to reach locations.
                      Research, Pipeline monitoring and repair, general
                      communication...
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 560 }}>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    title={
                      <PublicTwoToneIcon
                        style={{ color: "#07a8b3", fontSize: "65px" }}
                      />
                    }
                  />
                  <CardMedia
                    component="img"
                    image="/img/pexels-photo1.jpg"
                    title="call inmarsat"
                    alt="call inmarsat"
                  />
                  <Typography
                    variant="h5"
                    style={{
                      color: "#fff",
                      background: "#07a5a3",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    Government & Military
                  </Typography>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography variant="body1" color="textSecondary">
                      Cost control is a general characteristic that all
                      govenments excersize. All governments are strict on
                      controlling expenditure. Callinmarsat provides a solution
                      for everyone, by offering the tools necessary to assign...
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 560 }}>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    title={
                      <MapTwoToneIcon
                        style={{ color: "#07a8b3", fontSize: "65px" }}
                      />
                    }
                  />
                  <CardMedia
                    component="img"
                    image="/img/pexels-photo-209978.jpeg"
                    title="call inmarsat"
                    alt="call inmarsat"
                  />
                  <Typography
                    variant="h5"
                    style={{
                      color: "#fff",
                      background: "#07a5a3",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    Shipping & Boating industry
                  </Typography>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography variant="body1" color="textSecondary">
                      Some of our biggest customers are in the shipping
                      industry. WIth our solution they can assign local numbers
                      in any countrty and reach their vessels by dialling an
                      extention. They can assign pins to their local numbers
                      to...
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 560 }}>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    title={
                      <FavoriteTwoToneIcon
                        style={{ color: "#07a8b3", fontSize: "65px" }}
                      />
                    }
                  />
                  <CardMedia
                    component="img"
                    image="/img/career-firefighter-relaxing-job-162540.jpeg"
                    title="call inmarsat"
                    alt="call inmarsat"
                  />
                  <Typography
                    variant="h5"
                    style={{
                      color: "#fff",
                      background: "#07a5a3",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    Emergency Management & Disaster
                  </Typography>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography variant="body1" color="textSecondary">
                      Call quality and availability over a secure network are a
                      priority in these situations. Our network offers customers
                      multiple TIER 1 interconnections with multiple back up
                      routes, ensuring our customers that their call will...
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Card style={{ height: 560 }}>
                  <CardHeader
                    style={{ textAlign: "center" }}
                    title={
                      <LiveTvTwoToneIcon
                        style={{ color: "#07a8b3", fontSize: "65px" }}
                      />
                    }
                  />
                  <CardMedia
                    component="img"
                    image="/img/television-camera-men-outdoors-ballgame-159400.jpeg"
                    title="call inmarsat"
                    alt="call inmarsat"
                  />
                  <Typography
                    variant="h5"
                    style={{
                      color: "#fff",
                      background: "#07a5a3",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    Broadcasting & remote locations
                  </Typography>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography variant="body1" color="textSecondary">
                      Remote locations and broadcasting go hand in hand. Staff
                      and loved ones that are in remote areas without
                      infrastructure can now be reached as easily as calling
                      next door. We can route your call to any destination world
                      wide...
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <div className="bgimg-1">
            <div className={classes.container}>
              <GridItem cs={12} sm={12} md={12} style={{ paddingTop: "12%" }}>
                <Card style={{ opacity: "80%" }}>
                  <CardContent style={{ textAlign: "center" }}>
                    <h1 style={{ color: "#07a8b3" }}>
                      <b>Call Inmarsat Using EVCMOBI</b>
                    </h1>
                    <br />
                    <Typography variant="h6" color="textPrimary">
                      Download our FREE EVCMOBI application , add funds ( from
                      your mobile or from MYACCOUNT ), and call Inmarsat
                      directly from EVCMOBI at the lowest rates. Our EVCMOBI
                      app, is directly connected to the Inmarsat network.
                      providing HD qualiy voice, convenience and the lowest
                      calling rates.
                      <br />
                      <br />
                      Save up to 75% on your calls to inmarsat.
                      <br />
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
            </div>
          </div>
          <div className={classes.container}>
            <GridContainer justify-items ="center">
              <GridItem cs={12} sm={12} md={6}>
                <CardMedia
                  className={classes.media}
                  component="img"
                  image="img/callinmarsat-phone.png"
                  alt="call inmarsat"
                  title="call inmarsat"
                />
              </GridItem>
              <GridItem cs={12} sm={12} md={6}>
                <h2 style={{ color: "#07a8b3" }}>
                  <b>
                    Awesome benefits! A unique and dedicated local phone number
                    for each satellite phone
                  </b>
                </h2>
                <Grid container spacing={2}>
                  <Grid item>
                    <TuneIcon
                      fontSize="large"
                      style={{ color: "#07a8b3", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item>
                    <h3 style={{ color: "#07a8b3" }}>
                      <b>Increase Efficiency</b>
                    </h3>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item>
                    <OfflineBoltIcon
                      fontSize="large"
                      style={{ color: "#07a8b3", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item>
                    <h3 style={{ color: "#07a8b3" }}>
                      <b>Easy to Get Started</b>
                    </h3>
                  </Grid>
                  <Typography variant="body1" color="textPrimary">
                    Getting started is as easy as 1-2-3. Select the solution
                    that fits your needs and start calling within minutes.
                    Calling Inmarsat has never been easier.
                  </Typography>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item>
                    <LocalAtmIcon
                      fontSize="large"
                      style={{ color: "#07a8b3", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item>
                    <h3 style={{ color: "#07a8b3" }}>
                      <b>Save Money</b>
                    </h3>
                  </Grid>
                  <Typography variant="body1" color="textPrimary">
                    Save up to 75% on your per minute usage! Call local and talk
                    global avoiding international call costs and surcharges.
                    Purchase local numbers without activation fees and
                    commitments.
                  </Typography>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item>
                    <CloudDoneTwoToneIcon
                      fontSize="large"
                      style={{ color: "#07a8b3", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item>
                    <h3 style={{ color: "#07a8b3" }}>
                      <b>Dependable and flexible</b>
                    </h3>
                  </Grid>
                  <Typography variant="body1" color="textPrimary">
                    Meet all your current and future demands, with Callinmarsat.
                    All our solutions are scalable and without commitment
                    offering the lowest rates to calling not only Inmarsat but
                    any number world wide. Change the way you call inmarsat
                    today.
                  </Typography>
                </Grid>
              </GridItem>
            </GridContainer>
          </div>
          <GridContainer
            justify="center"
            style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }}
          >
            <GridItem
              cs={12}
              sm={12}
              md={6}
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
            >
              <CardMedia
                component="img"
                image="img/pexels-photo-212289_0.jpeg"
                alt="call inmarsat"
                title="call inmarsat"
              />
            </GridItem>
            <GridItem cs={12} sm={12} md={6} style={{ background: "#20d0d6" }}>
              <h2 style={{ color: "#fff" }}>
                <b>Your Dilemma - Our Solution</b>
              </h2>
              <Typography variant="h5" style={{ color: "#fff" }}>
                <ul>
                  <li>
                    14+ digit numbers that are confusing and difficult to use.
                    Register a local number in your area and call locally with
                    ease, we will do the rest, connecting you to your satellite
                    number.
                  </li>
                  <li>
                    Expensive long-distance charges when calling a satellite
                    phone. By dialing any of our local numbers, or using our
                    Free apps, our customers benefit without surcharge fees from
                    their mobile providers, and our low direct dialing rates.
                  </li>
                  <li>
                    Many countries and carriers restrict calling satellite
                    phones. There are no calling restrictions on any of our
                    solutions.
                  </li>
                  <li>
                    Many companies experience a lack of control and
                    accountabiliy with regards to cost and usage calling
                    inmarsat or other sattellite numbers. Our user friendly
                    customer portal provides an easy way to monitor in real time
                    all your costs and usage. By deploying our pin service you
                    can enforce restrictions on who can have access to the
                    inmarsat call.
                  </li>
                </ul>
              </Typography>
            </GridItem>
          </GridContainer>
          <div className={classes.container}>
            <h2 style={{ color: "#07a8b3", textAlign: "center" }}>
              <b>Callinmarsat Features</b>
            </h2>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item>
                    <ListAltTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>
                      Whitelist your devices and contacts
                    </h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <PanToolTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>Block unwanted calls</h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <AccountCircleTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>
                      Personal and business accounts
                    </h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <VpnLockTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>Securtiy</h3>
                  </Grid>
                </Grid>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item>
                    <FindInPageTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>All in one billing</h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <AccountCircleTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>
                      Callinmarsat customer portal
                    </h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <ForumTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>
                      Advanced calling features
                    </h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <PhoneAndroidTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>
                      Callinmarsat Local Number
                    </h3>
                  </Grid>
                </Grid>
              </GridItem>
              <GridItem cs={12} sm={12} md={4}>
                <Grid container spacing={2}>
                  <Grid item>
                    <BusinessCenterTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>Business Solutions</h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <CallSplitTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>
                      Change your destination number
                    </h3>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item>
                    <OfflineBoltTwoToneIcon
                      fontSize="large"
                      style={{ color: "#666666", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <h3 style={{ color: "#07a8b3" }}>
                      Fast and FREE Activation
                    </h3>
                  </Grid>
                </Grid>
              </GridItem>
            </GridContainer>
          </div>
          <div className="bgimg-2">
            <div className={classes.container}>
              <GridItem cs={12} sm={12} md={12} style={{ paddingTop: "12%" }}>
                <Card style={{ opacity: "80%" }}>
                  <CardContent>
                    <h1 style={{ color: "#07a8b3", textAlign: "center" }}>
                      <b>65+ Countries</b>
                    </h1>
                    <br />
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      style={{ textAlign: "center" }}
                    >
                      Callinmarsat provides local phone numbers for satellite
                      phones in over 65+ different countries and 1000's of
                      cities! Register a local number today and start saving.
                      With callinmarsat you get:
                    </Typography>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      style={{ paddingLeft: "15%" }}
                    >
                      <ol>
                        <li>Instant Online and FREE Activation</li>
                        <li>No Software necessary</li>
                        <li>Supports all Networks</li>
                        <li>The Lowest Calling Rates</li>
                      </ol>
                    </Typography>
                  </CardContent>
                </Card>
              </GridItem>
            </div>
          </div>
          <div className={classes.container}>
            <WorkSection />
          </div>
          <VisaSection />
          <Footer />

          <ScrollUpButton />
        </div>
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ agree"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(7, 168, 179)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          We use cookies on this site to improve your user experience.{" "}
          <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              More information
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(HomePage);
