import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "components/CustomButtons/Button.jsx";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import MUIDataTable from "mui-datatables";
import ReactCountryFlag from "react-country-flag";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

import { connect } from "react-redux";
import { getDIDCountries } from "actions/retailActions";

import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";


class DIDsPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: "",
      loading_rates: true
    };
  }
  
  getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          fontSize: "1.5em",
          paddingTop: "5px",
          paddingBottom: "5px"
        },
        stackedCommon: { 
          "@media screen and (max-width: 960px)": { 
            height: "100%"
          }
        }
      }
    }
  })
  
  async componentDidMount() {
    await this.props.getDIDCountries();
    this.setState({
      loading_rates: false
    });
    
  }

  render() {
    const { classes, did_countries, ...rest  } = this.props;
    const {
      loading_rates
    } = this.state;

    const data = did_countries.map(row =>
      [
        
        <ReactCountryFlag code={row.country_code} svg />, 
        row.country_name+" ("+row.country_phonecode+")",
        row.setup_fee, 
        row.monthly_fee, 
        <Button
        color="primary"
        size="sm"
        href={"/didareas/"+row.id}
        rel="noopener noreferrer"
      >
        Select
      </Button>
      ]
    );

    const columns = [
      {
        name: "",
        options: {
          filter: false,
          print: false,
          sort: false,
        }
      }, 
      {
        name: "Name/Phone Code",
        options: {
          filter: false,
          print: false,
        }
      }, 
      {
        name: "Setup Fee $",
        options: {
          filter: false,
          print: false,
        }
      }, 
      {
        name: "Mothly Fee $",
        options: {
          filter: false,
          print: false,
        }
      }, 
      {
        name: "Select Country",
        options: {
          filter: false,
          sort: false,
          print: false,
        }
      },
    ];

    

const options = {
    filter: false,
    print: false,
    selectableRows: false,
    responsive: 'stacked' ,
    download: false,
    viewColumns: false,
    rowsPerPage: 10
};
    return (
      <div>
         <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            {"SELECT A COUNTRY FOR YOUR LOCAL DID NUMBER"}
	      </h2>
		<div className={classes.container} style={{ marginTop: "40px" }}>
		<ListItem>
                  <Typography
                    component="h5"
                    variant="body1"
                    color="textPrimary"
                  >
				    <b>3 EASY STEPS to follow :</b><p></p>
                    1) Select a <b>LOCAL NUMBER</b><i> (Everytime you call your LOCAL NUMBER we will connect you to your DESTINATION NUMBER (Inmarsat number))</i> <p></p>
					2) <b>Fill in your details</b> and <b>SET</b> your <b>DESTINATION NUMBER.</b><p></p>
					3) <b>Select a bundle</b> for additional savings <b>-or- Top Up</b> and pay as-you-go. <p> </p>
				    </Typography>
            </ListItem>
			</div>
        <div className={classes.container} style={{ marginTop: "40px" }}>
            {loading_rates ? (
              <GridContainer justify="center">
                <CircularProgress size={50} />
              </GridContainer>
            ) : (
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable 
                    title={"Countries"} 
                    data={data} 
                    columns={columns} 
                    options={options} 
                />
              </MuiThemeProvider>
            )}
        </div> 
          <VisaSection />
          <Footer />
        <ScrollUpButton />
      </div>
      <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

DIDsPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
    did_countries: state.retail_reducer.did_countries
  });

export default connect(
    mapStateToProps,
  { getDIDCountries }
)(withStyles(landingPageStyle)(DIDsPage)) ;
