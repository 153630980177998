import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Typography from "@material-ui/core/Typography";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

class Privacy extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h1
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            {"PRIVACY POLICY - TERMS AND CONDITIONS"}
          </h1>
          <div className={classes.container}>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Privacy Policy</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            EasyVoipCall Inc. ("EasyVoipCall") will not trade, sell, or disclose to any third party any form of customer identifiable information without the consent of the customer (except as required by subpoena, search warrant or other legal process or in the case where failure to disclose information will lead to imminent harm to the customer or others). This includes information derived from registration, subscription, and use of the EasyVoipCall service.
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Collection / Use of Information</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            EasyVoipCall Inc. ("EasyVoipCall") will not trade, sell, or disclose to any third party any form of customer identifiable information without the consent of the customer (except as required by subpoena, search warrant or other legal process or in the case where failure to disclose information will lead to imminent harm to the customer or others). This includes information derived from registration, subscription, and use of the EasyVoipCall service.
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Security</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            EasyVoipCall has deployed a wide variety of technology and security features to ensure the privacy of customer information on its network. In addition, EasyVoipCall has implemented strict operations guidelines to ensure customer privacy is safeguarded at every level of its organization. EasyVoipCall will continue to revise policies and implement additional security features as new technologies becomes available.
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Communications</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            EasyVoipCall will not read, listen to or disclose to any third parties private e-mail, conversations, or other communications that are transmitted using EasyVoipCall services except as required to ensure proper operation of services or as otherwise authorized by law.
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Unlawful Activity</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            EasyVoipCall may use customer identifiable information to investigate and help prevent potentially unlawful activity or activities that threaten the integrity of service or network integrity or otherwise violate EasyVoipCall's Service Level Agreement.
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Account Information</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            EasyVoipCall will do its best to honor requests from customers for account information, for example, name, address, company, or billing information. The customer is responsible for ensuring that the information on file with EasyVoipCall is current and accurate.
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Children's Privacy Policy</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            EasyVoipCall does not sell products or services for purchase by children. EasyVoipCall does not knowingly solicit or collect customer identifiable information from children or teenagers under the age of eighteen. In addition, EasyVoipCall will not knowingly link to any third party web site that solicits or collects customer identifiable information from minors. If you believe that a minor has disclosed personally identifiable information to EasyVoipCall, please click here to contact us at so that the information can be removed.
              <br />
              Hellasfon.
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>EasyVoipCall Spam Policy</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
            EasyVoipCall has zero tolerance for spam. Spam complaints will be dealt with seriously and can result in losing EasyVoipCall privileges.
              <br />
            </Typography>
            
          </div>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

Privacy.propTypes = {
  classes: PropTypes.object
};

export default withStyles(landingPageStyle)(Privacy);
