import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import PlansTable from "./Sections/PlansTable.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "./Sections/VisaSection.jsx";
import RateSection from "./Sections/RateSection.jsx";
//import DIDSection from "./Sections/DIDSection.jsx";
import CookieConsent from "react-cookie-consent";
import GridContainer from "components/Grid/GridContainer.jsx";

import { connect } from "react-redux";
import { getPlans } from "actions/retailActions";


class RatesPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    this.state = { loading: false, plansState: []}
  }

  async UNSAFE_componentWillMount() {
    this.setState({loading: true});
    await this.props.getPlans();
    this.setState({loading: false});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.plans.length !== 0) {
      this.setState({
        plansState: nextProps.plans
      });
    }
  }

  selectPlan = async (id_plan, name, period_cost, selected) => {
    return
  };

  render() {
    const { classes, ...rest  } = this.props;
    const { loading, plansState } = this.state;

    return (
      <div>
         <Header
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            {"PAY AS YOU GO RATES FOR CALLS and SMS"}
          </h2>
        <div className={classes.container} style={{ marginTop: "40px" }}>
          <RateSection />
        </div>
          <h2 id="did"
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            {"AVAILABLE PLANS and BUNDLES"}
          </h2>
        <div className={classes.container} style={{ marginTop: "40px" }}>
          <GridContainer spacing={2}>
            {plansState.map(plan => (
                <PlansTable
                  disabled={loading}
                  plan={plan}
                  key={plan.id_plan}
                  selected={ false }
                  selectPlan={this.selectPlan}
                />
            ))}
          </GridContainer>
        </div>
          <VisaSection />
          <Footer />
        <ScrollUpButton />
      </div>
      <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
        </CookieConsent>
      </div>
    );
  }
}

RatesPage.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  plans: state.retail_reducer.plans
});

export default connect(
  mapStateToProps,
  { getPlans }
)(withStyles(landingPageStyle)(RatesPage)) ;
