import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Typography from "@material-ui/core/Typography";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import CardMedia from "@material-ui/core/CardMedia";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import LinkTwoToneIcon from "@material-ui/icons/LinkTwoTone";
import Button from "components/CustomButtons/Button.jsx";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

class Description extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white",
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px",
            }}
          >
            {"CALL INMARSAT SOLUTIONS"}
          </h2>
          <div className={classes.container}>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Solutions for everyone. Go Global Pay Local.</b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              Whether you are an individual or a business we have solutions
              tailored to fit each customer type and category.
              <br />
              <br />
              <b>Benefits</b> from using our services:
              <br />
            </Typography>
            <ul>
              <Typography variant="body1" color="textPrimary">
                <li>A <b>simple way to call</b> Inmarsat numbers </li>
                <li>Make your inmarsat <b>call by calling a local number.</b></li>
                <li>
                  <b>Save up to 75% </b>on all calls to Inmarsat or other Satellite
                  providers.
                </li>
                <li>
                  <b>Different solutions for diffferent customers</b> , providing the
                  same ease and economy.
                </li>
                <li>
                  <b>Online account</b> so you can monitor your calls and payments.
                </li>
              </Typography>
            </ul>
            <h2 style={{ color: "#07a8b3" }}>
              <b>Call Inmarsat Local Number solution: </b>
            </h2>
            <br />
            <Typography variant="body1" color="textPrimary">
              <b>Simple and easy to use.</b> Register a local number in your area and
              forward all your calls to the Inmarsat number. Call Inmarsat makes
              it easy and seamless. Call your local number and talk to the
              satellite phone or number with the lowest rates in the market.
              <b>Save up to 75% on all your calls</b>. Control your costs by purchasing
              any of our economic bundles or pay as you go with our low rates.
              Increase the security to your account by utilizing our pin
              recognition options and pin less dialling features. Call inmarsat
              is a call forwarding service. You can use Call inmarsat to call
              any destination world wide. Just change the destination number to
              the number you want to reach.
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>HOW TO GET STARTED</b>
            </h2>
            <ol>
              <Typography variant="body1" color="textPrimary">
                <li>
                  <b><a href="/buynow">Sign up</a></b> and create an account. Its easy and ready within
                  minutes.
                </li>
                <li>
                  <b>Purchase</b> one of our <b>bundles or add funds</b> for pay as you go.
                </li>
                <li>
                  During sign up , you <b>select a local number</b>, add funds, and <b>set
                  your Inmarsat number</b> ( we refer to this as the: destination
                  number ).
                </li>
                <li>
                  Upon successful <b>registration</b> the service is immediately <b>ready
                  for use</b>.
                </li>
              </Typography>
            </ol>
            <Typography variant="body1" color="textPrimary">
              You can log in to your account, see your call detail records,
              change your plan, add funds to your account, or change the
              destination number.
              <br />
              <br />
              Although we offer this service to our Maritime and shiping
              customers, this service can be used to call any number world wide.
              All you have to do is to change the destination number from your
              portal.
              <br />
              <br />
              THAT'S IT ! Everytime <b>you call your local number and we will
              connect you to your destination number.</b> ( Any number you want to
              reach ).
              <br />
            </Typography>
            <h2 style={{ color: "#07a8b3" }}>
              <b>OTHER SOLUTIONS WE OFFER our SAT and business customers:</b>
            </h2>
            <GridContainer justify="center">
              <GridItem cs={12} sm={12} md={3}>
                <CardMedia
                  style={{ borderRadius: "10%" }}
                  component="img"
                  image="img/iphone-evcmobi-extra-features_sip.png"
                  alt="call inmarsat"
                  title="call inmarsat"
                />
              </GridItem>
              <GridItem cs={12} sm={12} md={7}>
                <h2 style={{ color: "#07a8b3" }}>
                  <b>IP interconection or SIP TRUNK</b>
                </h2>
                <Typography variant="body1" color="textPrimary">
                  Direct interconnection to our network, multiple Tier 1
                  providers offering redundancy and high call completion on all
                  calls. A perfect and easy solution for telecom operators or
                  shipping companies looking for an economic good quality route
                  to call inmarsat and other satellite destinations. Contact us
                  and send us your request. We have the solution for you.
                  <br />
                </Typography>
                <Button
                  fullWidth
                  style={{ fontSize: "20px" }}
                  color="primary"
                  href="/siptrunk"
                  rel="noopener noreferrer"
                  startIcon={<LinkTwoToneIcon />}
                >
                  VOIP SIP TRUNKING
                </Button>
                <br /> <br />
              </GridItem>
              <GridItem cs={12} sm={12} md={3}>
                <CardMedia
                  style={{ borderRadius: "10%" }}
                  component="img"
                  image="img/iphone-evcmobi-extra-features_evcmobi.png"
                  alt="call inmarsat"
                  title="call inmarsat"
                />
              </GridItem>
              <GridItem cs={12} sm={12} md={7}>
                <h2 style={{ color: "#07a8b3" }}>
                  <b>EVCMOBI</b>
                </h2>
                <Typography variant="body1" color="textPrimary">
                  EVCMOBI is free. It is interconnected with all the satellite
                  networks , giving you direct calling to all your inmarsat
                  numbers right from your mobile. Download the app, add funds
                  and call. EVCMOBI saves you money on ALL calls, local,
                  international and satellite. TRY it today! Contact us with
                  your request.
                  <br />
                </Typography>
                <Button
                  fullWidth
                  style={{ fontSize: "20px" }}
                  color="primary"
                  href="https://evcmobi.com/"
                  rel="noopener noreferrer"
                  startIcon={<LinkTwoToneIcon />}
                >
                  CHEAP VOIP CALLS
                </Button>
                <br />
                <br />
              </GridItem>
              <GridItem cs={12} sm={12} md={3}>
                <CardMedia
                  style={{ borderRadius: "10%" }}
                  component="img"
                  image="img/evcoffice.png"
                  alt="call inmarsat"
                  title="call inmarsat"
                />
              </GridItem>
              <GridItem cs={12} sm={12} md={7}>
                <h2 style={{ color: "#07a8b3" }}>
                  <b>EVCOFFICE</b>
                </h2>
                <Typography variant="body1" color="textPrimary">
                  EVCOFFICE cloud PBX. Turn Key solution! Call at the lowest
                  rates, reach your satellite numbers by dialling an extension.
                  Up and ready in 5 minutes. Without commitments. Sign up online
                  and get started right away. Not so Tech savy? need some help
                  to set it up? Contact us with your request, we would be happy
                  to hear from you.
                  <br />
                </Typography>
                <Button
                  fullWidth
                  style={{ fontSize: "20px" }}
                  color="primary"
                  href="https://evcoffice.com/"
                  rel="noopener noreferrer"
                  startIcon={<LinkTwoToneIcon />}
                >
                  CHEAP VOIP PBX OFFICE
                </Button>
              </GridItem>
              <GridItem cs={12} sm={12} md={3}>
                <CardMedia
                  style={{ borderRadius: "10%", marginTop: "20px" }}
                  component="img"
                  image="/img/30travelsim.png"
                  alt="call inmarsat"
                  title="call inmarsat"
                />
              </GridItem>
              <GridItem cs={12} sm={12} md={7}>
                <h2 style={{ color: "#07a8b3" }}>
                  <b>360 Travel sim</b>
                </h2>
                <Typography variant="body1" color="textPrimary">
                  360Travelsim caters to leisure and business travellers. Say NO
                  to ROAMING with 360Travelsim. It is especially popular with
                  shipping companies and their crew. Travel the world with
                  360Travelsim and save on your international roaming fees by
                  75% ! Sign up and purchase your card and air time online. Need
                  some help? Contact us with your reguest.
                  <br />
                </Typography>
                <Button
                  fullWidth
                  style={{ fontSize: "20px" }}
                  color="primary"
                  href="https://360roaming.com/"
                  rel="noopener noreferrer"
                  startIcon={<LinkTwoToneIcon />}
                >
                  CHEAP SIM ROAMING CARDS
                </Button>
              </GridItem>
              <GridItem cs={12} sm={12} md={3}>
                <CardMedia
                  style={{ borderRadius: "10%", marginTop: "20px" }}
                  component="img"
                  image="/img/iot.png"
                  alt="call inmarsat"
                  title="call inmarsat"
                />
              </GridItem>
              <GridItem cs={12} sm={12} md={7}>
                <h2 style={{ color: "#07a8b3" }}>
                  <b>360 IOT sim</b>
                </h2>
                <Typography variant="body1" color="textPrimary">
                  Global , multi vendor, corporate GSM services offered world
                  wide. Ask about our POOL sharing options for our corporate
                  customers. Corporate bundles can be pooled and shared amongst
                  all your staff.
                  <br />
                </Typography>
                <Button
                  fullWidth
                  style={{ fontSize: "20px" }}
                  color="primary"
                  href="https://360roaming.com/"
                  rel="noopener noreferrer"
                  startIcon={<LinkTwoToneIcon />}
                >
                  CORPORATE GSM SERVICES
                </Button>
              </GridItem>
            </GridContainer>
          </div>
          <VisaSection />
          <Footer />
        </div>
        <ScrollUpButton />
        <CookieConsent
          location="bottom"
          buttonText="ΟΚ agree"
          cookieName="hellasfonCookie"
          style={{ background: "rgb(7, 168, 179)" }}
          buttonStyle={{
            background: "#f50057",
            color: "white",
            fontSize: "15px",
            marginRight: "130px",
          }}
          expires={150}
        >
          We use cookies on this site to improve your user experience.{" "}
          <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a
              style={{ color: "white", textDecoration: "underline" }}
              href="/privacy"
            >
              More information
            </a>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

Description.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(landingPageStyle)(Description);
