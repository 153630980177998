import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import HeaderTopLinks from "components/Header/HeaderTopLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "components/CustomButtons/Button.jsx";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import MUIDataTable from "mui-datatables";
import ReactCountryFlag from "react-country-flag";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

import VisaSection from "./Sections/VisaSection.jsx";
import CookieConsent from "react-cookie-consent";

import { connect } from "react-redux";
import { getAreas, getDIDCountry } from "actions/retailActions";


class AreasPage extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const { classes } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      response: ""
    };
  }
  
  async UNSAFE_componentWillMount() {
    const { id } = this.props.match.params;
    await this.props.getAreas(id);
    await this.props.getDIDCountry(id);
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
        MUIDataTableBodyCell: {
          root: {
            fontSize: "1.5em",
            paddingTop: "5px",
            paddingBottom: "5px"
          },
          stackedCommon: { 
            "@media screen and (max-width: 960px)": { 
              height: "100%"
            }
          }
        }
      }
  })
  render() {
    const { classes, areas, did_country, response, loading, ...rest  } = this.props;
    const data = areas.map(row =>
      [
        <ReactCountryFlag code={did_country.country_code} svg />, 
        row.code,
        row.name, 
        <Button
        color="primary"
        size="sm"
        href={"/signup/"+did_country.id+"/"+row.code+"/"+row.name}
        rel="noopener noreferrer"
      >
        BUY A NUMBER
      </Button>
      ]
    );

    const columns = [
      {
        name: "",
        options: {
          filter: false,
          print: false,
          sort: false,
        }
      }, 
      {
        name: "Phone Code",
        options: {
          filter: false,
          print: false,
        }
      }, 
      {
        name: "Name",
        options: {
          filter: false,
          print: false,
        }
      }, 
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          print: false,
        }
      },
    ];

const options = {
    filter: false,
    print: false,
    selectableRows: false,
    responsive: 'stacked' ,
    download: false,
    viewColumns: false,
    rowsPerPage: 100
};
    return (
      <div>
         <Header
          topLinks={<HeaderTopLinks />}
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          logoUrl="/img/callinmarsat_logo.png"
          changeColorOnScroll={{
            height: 50,
            color: "white"
          }}
          {...rest}
        />
        <Parallax color small />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#07a8b3",
              color: "white",
              paddingTop: "15px"
            }}
          >
            {"SELECT AREA FOR YOUR LOCAL DID NUMBER"}
          </h2>
        <div className={classes.container} style={{ marginTop: "40px" }}>
            {loading ? (
                <GridContainer justify="center">
                  <CircularProgress size={50} />
                </GridContainer>
            ) : (
              <React.Fragment>
                <Typography component="h4" variant="h4" color="primary" style={{ textAlign: "center", marginBottom: "10px"}}> {did_country.country_name} ({did_country.country_phonecode}) /month {did_country.monthly_fee} $</Typography>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable 
                      title={"Plaese select area"} 
                      data={data} 
                      columns={columns} 
                      options={options} 
                  />
                </MuiThemeProvider>
              </React.Fragment>
            )}
        </div> 
          <VisaSection />
          <Footer />
        <ScrollUpButton />
      </div>
      <CookieConsent
            location="bottom"
            buttonText="ΟΚ agree"
            cookieName="hellasfonCookie"
            style={{ background: "rgb(7, 168, 179)" }}
            buttonStyle={{ background: "#f50057", color: "white", fontSize: "15px", marginRight: "130px" }}
            expires={150}
        >
            We use cookies on this site to improve your user experience.{" "}
            <span style={{ fontSize: "12px" }}>
            Clicking on any link on this page gives your consent to cookies.{" "}
            <a style={{color: "white", textDecoration: "underline"}} href="/privacy">More information</a>
            </span>
            
        </CookieConsent>
      </div>
    );
  }
}

AreasPage.propTypes = {
  classes: PropTypes.object,
  did_country: PropTypes.object,
  areas: PropTypes.array
};

const mapStateToProps = state => ({
    areas: state.retail_reducer.areas,
    did_country: state.retail_reducer.did_country,
    loading: state.retail_reducer.loading
  });

export default connect(
    mapStateToProps,
  { getAreas, getDIDCountry }
)(withStyles(landingPageStyle)(AreasPage)) ;