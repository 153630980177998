import React from "react";
import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.jsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx";

import { connect } from "react-redux";
import {
  removeOrder,
  createOrder,
  updateAlphaOrder,
} from "actions/retailActions";

class Review extends React.Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    const {
      classes,
      email,
      address,
      city,
      products,
      plans,
      top_up,
      order_axios,
    } = props;
    this.state = {
      classSnackbar: classes.hideSnackbar,
      classSnackbarPay: classes.hideSnackbar,
      response: "",
      response_payment: "",
      value_radio: "paypal",
      loading: false,
      marchantEnable: true,
      paymentID: "",
      paymentToken: "",
      plans: plans,
      top_up: top_up,
      products: products,
      addresses: [address, city, email],
      order_axios: order_axios,
    };
    console.log(email, address, city, products, plans, top_up, order_axios);
  }

  handleChange = (e) =>
    this.setState({
      value_radio: e.target.value,
    });

  onPaypal = async (e) => {
    const { order_axios, country } = this.props;

    const { loading, products } = this.state;

    let total = 0;
    if (country.country_name === "Canada") {
      for (let num of products) {
        total = total + (parseFloat(num.price) * 0.13 + parseFloat(num.price));
      }
    } else {
      for (let num of products) {
        total = total + parseFloat(num.price);
      }
    }

    if (!loading) {
      this.setState({
        loading: true,
        marchantEnable: false,
      });

      //paypal return url sent to api read amout, marchant id if match success, else cancel

      let form = document.createElement("form");
      form.method = "POST";
      form.action = "https://www.paypal.com/cgi-bin/webscr";

      const bn = "_PP-BuyNowBF";
      const business = "christo@easyvoipcall.com";
      const item_name = "Callinmarsat Registration";
      const amount = total.toFixed(2);
      const no_note = "1";
      const rm = "1";
      const charset = "utf-8";
      const cancel_return =
        "https://sip1.easyvoipcall.com/pbxres/api/cfpaypal" +
        order_axios.order_id +
        ".cancel";
      const currency_code = "USD";
      const lc = "CA";
      const custom = order_axios.order_id;
      const paymentaction = "sale";

      const content = [
        {
          Name: "cmd",
          Value: "_xclick",
        },
        {
          Name: "bn",
          Value: bn,
        },
        {
          Name: "business",
          Value: business,
        },
        {
          Name: "item_name",
          Value: item_name,
        },
        {
          Name: "amount",
          Value: amount,
        },
        {
          Name: "no_note",
          Value: no_note,
        },
        {
          Name: "rm",
          Value: rm,
        },
        {
          Name: "charset",
          Value: charset,
        },
        {
          Name: "form-charset",
          Value: "utf-8",
        },
        {
          Name: "return",
          Value:
            "https://sip1.easyvoipcall.com/pbxres/api/cfpaypal" +
            order_axios.order_id +
            ".back",
        },
        {
          Name: "notify_url",
          Value: "https://sip1.easyvoipcall.com/pbxres/api/paypal.notify",
        },
        {
          Name: "cancel_return",
          Value: cancel_return,
        },
        {
          Name: "currency_code",
          Value: currency_code,
        },
        {
          Name: "lc",
          Value: lc,
        },
        {
          Name: "custom",
          Value: custom,
        },
        {
          Name: "paymentaction",
          Value: paymentaction,
        },
      ];

      for (let i in content) {
        let f = content[i];
        var el = document.createElement("input");
        el.value = f.Value;
        el.name = f.Name;
        form.appendChild(el);
      }

      document.body.appendChild(form);
      form.submit();
    }
  };
  onClick = async (e) => {
    e.preventDefault();

    const {
      classes,
      firstName,
      lastName,
      email,
      phonemobile,
      address,
      accept,
      phonemove,
      city,
      products,
      plans,
      top_up,
      order_axios,
    } = this.props;

    /*
    // delete order
    this.setState({ loading: true });
    await this.props.removeOrder(order_axios);
    if (Object.values(this.props.error_axios).length !== 0) {
      // display error mesage
      this.setState({
        loading: false,
        classSnackbar: classes.errorSnackbar,
        response: "Something went wrong again. Please try again.",
      });
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
    */
    this.props.handleBack(
      firstName,
      lastName,
      email,
      phonemobile,
      address,
      accept,
      phonemove,
      city,
      products,
      plans,
      top_up
    );
  };

  render() {
    const {
      products,
      addresses,
      value_radio,
      loading,
      response,
      response_payment,
      classSnackbar,
      classSnackbarPay,
      marchantEnable,
    } = this.state;
    const { activeStep, classes, firstName, lastName, country } = this.props;

    let total = 0;
    let totalVat = 0;
    if (country.country_name === "Canada") {
      for (let num of products) {
        total = total + (parseFloat(num.price) * 0.13 + parseFloat(num.price));
        totalVat = totalVat + parseFloat(num.price).toFixed(2) * 0.13;
      }
    } else {
      for (let num of products) {
        total = total + parseFloat(num.price);
      }
    }
    return (
      <React.Fragment>
        <form>
          <Typography variant="h6" gutterBottom color="primary">
            SHOPPING CART CONTENTS
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">VAT 13%</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product.name}>
                    <TableCell component="th" scope="row">
                      <ListItemText
                        primary={product.name}
                        secondary={product.desc}
                      />
                    </TableCell>
                    <TableCell align="right">{product.price}</TableCell>
                    <TableCell align="right">1</TableCell>
                    <TableCell align="right">
                      {country.country_name === "Canada"
                        ? (parseFloat(product.price) * 0.13).toFixed(2)
                        : null}
                    </TableCell>
                    <TableCell align="right">
                      {country.country_name === "Canada"
                        ? (
                            parseFloat(product.price) * 0.13 +
                            parseFloat(product.price)
                          ).toFixed(2)
                        : parseFloat(product.price).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">
                    {country.country_name === "Canada"
                      ? "$ " + parseFloat(totalVat).toFixed(2)
                      : null}
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h5" className={classes.total}>
                      $ {parseFloat(total).toFixed(2)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom color="primary">
                BILLING INFORMATION
              </Typography>
              <Typography gutterBottom>
                {firstName} {lastName}
              </Typography>
              <Typography gutterBottom>{addresses.join(", ")}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" gutterBottom color="primary">
                PAYMENT
              </Typography>
              <RadioGroup
                aria-label="payment"
                id="payment"
                value={value_radio}
                onChange={this.handleChange}
              >
                <FormControlLabel
                  value="paypal"
                  control={<Radio />}
                  label={
                    <img
                      src="/img/paypal-credit-card-logos-png.png"
                      alt="Paypal"
                      height="100"
                    />
                  }
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <GridContainer className={classes.buttons}>
            <GridItem xs={12} sm={12} md={12} className={classes.textEnd}>
              {activeStep !== 0 && marchantEnable && (
                <Button
                  onClick={this.onClick}
                  className={classes.button}
                  disabled={loading}
                >
                  Back
                </Button>
              )}
              <Button
                onClick={this.onPaypal}
                className={classes.button}
                disabled={loading}
                color="primary"
              >
                Place your order
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              <SnackbarContent
                className={classSnackbarPay}
                aria-describedby="client-snackbar_payment"
                message={response_payment}
              />
              <SnackbarContent
                className={classSnackbar}
                aria-describedby="client-snackbar"
                message={response}
              />
            </GridItem>
          </GridContainer>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  create_retail: state.retail_reducer.create_retail,
  error_axios: state.retail_reducer.error_axios,
});

export default connect(
  mapStateToProps,
  { removeOrder, createOrder, updateAlphaOrder }
)(withStyles(workStyle)(Review));
