import React from "react";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import AddressForm from "./Register/AddressForm";
import PlansForm from "./Register/PlansForm";
import Review from "./Register/Review";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ReactCountryFlag from "react-country-flag";
import PhoneForm from "./Register/PhoneForm";

const checkoutStyle = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    [theme.breakpoints.down("sm")]: {
      display: "flow-root",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

const steps = ["Contact details", "Mobile verify", "Products", "Review"];

class CheckoutSection extends React.Component {
  constructor(props) {
    super(props);
    const { redirect, check_order, country, area } = props;

    this.state = {
      activeStep:
        (redirect === "sucess" && check_order) ||
        (redirect === "cancel" && check_order)
          ? 3
          : redirect === undefined
          ? 0
          : (window.location = "/"),
      firstName: "",
      lastName: "",
      email: "",
      check_email: "",
      phonemobile: "",
      address: "",
      phonemove: "",
      accept: false,
      city: "",
      country: country,
      area: area,
      verify_mobile: "",
      products: [
        {
          id: 1,
          name:
            "DID number " +
            country.country_name +
            " " +
            area[0].name +
            " (+" +
            country.country_phonecode +
            " " +
            area[0].code +
            ")",
          desc:
            "Monthly cost: " +
            country.monthly_fee +
            " Setup cost: " +
            country.setup_fee,
          price: (
            parseFloat(country.monthly_fee) + parseFloat(country.setup_fee)
          ).toFixed(2),
        },
        {
          id: 2,
          name: "TOP UP",
          desc: "",
          price: (0.0).toFixed(2),
        },
      ],
      plans: [],
      top_up: 0,
      order_axios: {},
    };
    this.getStepContent = this.getStepContent.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  getStepContent(step, steps) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            address={this.state.address}
            accept={this.state.accept}
            phonemove={this.state.phonemove}
            city={this.state.city}
            country={this.state.country}
            area={this.state.area}
            products={this.state.products}
            plans={this.state.plans}
            top_up={this.state.top_up}
          />
        );
      case 1:
        return (
          <PhoneForm
            activeStep={step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            address={this.state.address}
            accept={this.state.accept}
            phonemove={this.state.phonemove}
            city={this.state.city}
            country={this.state.country}
            area={this.state.area}
            products={this.state.products}
            plans={this.state.plans}
            top_up={this.state.top_up}
            verify_mobile={this.state.verify_mobile}
          />
        );
      case 2:
        return (
          <PlansForm
            activeStep={step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phonemobile={this.state.phonemobile}
            address={this.state.address}
            accept={this.state.accept}
            phonemove={this.state.phonemove}
            city={this.state.city}
            country={this.state.country}
            area={this.state.area}
            plansInit={this.props.plans}
            products={this.state.products}
            plans={this.state.plans}
            top_up={this.state.top_up}
            verify_mobile={this.state.verify_mobile}
          />
        );
      case 3:
        return (
          console.log(
            this.state.firstName,
            this.state.lastName,
            this.state.email,
            this.state.address,
            this.state.phonemove,
            this.state.city,
            this.state.top_up
          ),
          (
            <Review
              activeStep={step}
              handleNext={this.handleNext}
              handleBack={this.handleBack}
              firstName={this.state.firstName}
              lastName={this.state.lastName}
              email={this.state.email}
              address={this.state.address}
              accept={this.state.accept}
              phonemove={this.state.phonemove}
              city={this.state.city}
              country={this.state.country}
              area={this.state.area}
              products={this.state.products}
              plans={this.state.plans}
              top_up={this.state.top_up}
              order_axios={this.state.order_axios}
              phonemobile={this.state.phonemobile}
            />
          )
        );
      default:
        return (
          <AddressForm
            activeStep={step}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
          />
        );
    }
  }
  handleNextReview(verify_mobile) {
    window.scrollTo(0, 0);
    this.setState({
      activeStep: this.state.activeStep + 1,
      verify_mobile: verify_mobile,
    });
  }

  async handleNext(
    firstName,
    lastName,
    email,
    phonemobile,
    address,
    accept,
    phonemove,
    city,
    products,
    verify_mobile,
    plans,
    top_up,
    order_axios
  ) {
    await this.setState({
      activeStep: this.state.activeStep + 1,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phonemobile: phonemobile,
      address: address,
      accept: accept,
      phonemove: phonemove,
      city: city,
      products: products,
      plans: plans,
      top_up: top_up,
      order_axios: order_axios,
      verify_mobile: verify_mobile,
    });
  }
  handleBack(
    firstName,
    lastName,
    email,
    phonemobile,
    address,
    accept,
    phonemove,
    city,
    products,
    plans,
    top_up
  ) {
    this.setState({
      activeStep: this.state.activeStep - 1,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phonemobile: phonemobile,
      address: address,
      accept: accept,
      phonemove: phonemove,
      city: city,
      products: products,
      plans: plans,
      top_up: top_up,
    });
  }
  render() {
    const { classes, redirect, order, country, area } = this.props;
    const { activeStep, verify_mobile } = this.state;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" align="center">
              Number from <ReactCountryFlag code={country.country_code} svg />{" "}
              {country.country_name} ({country.country_phonecode})
            </Typography>
            <Typography variant="h4" align="center">
              Area {area.map((a) => a.name)} {area.map((a) => a.code)} / monthly{" "}
              {country.monthly_fee} $
            </Typography>
            <Typography variant="h6" align="center">
              {
                "Please notice that you must provide the following information in order to activate your Callinmarat Service Phone Registration"
              }
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && redirect === "sucess" ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom color="primary">
                  Great ! Yur order Nr:<strong> # {order} </strong> has been
                  compleated.
                </Typography>
                <Typography variant="subtitle1">
                  We have e-mailed you confirmation details.
                  <br /> Please check your email.
                  <br /> If you didn't receive the email please contact
                  support@callinmarsat.com
                </Typography>
              </React.Fragment>
            ) : activeStep === steps.length && redirect === "cancel" ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom color="secondary">
                  TRANSACTION FOR YOUR ORDER <strong> # {order} </strong>{" "}
                  CANCELED
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Common Reasons for Not Performing the Transaction
                </Typography>
                <Typography variant="h6" gutterBottom>
                  1. Incorrect Registration of Your Card Information <br />
                  2. Exceeding Your Card Credit Limit <br />
                  3. Low Quality of Your Internet Connection <br />
                  4. Sudden Stop Your Internet Connection <br />
                  5. Canceled on transaction site <br />
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is <strong> # {order} </strong>. Contact
                  support@callinmarsat.com to complete your payment.
                </Typography>
              </React.Fragment>
            ) : activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Great ! Yur order Nr:<strong> # {order} </strong> has been
                  compleated.
                </Typography>
                <Typography variant="subtitle1">
                  We have e-mailed you confirmiation details.{" "}
                  <strong>#{verify_mobile.order_id}</strong>
                  <br /> Please check your email.
                  <br /> If you didn't receive the email please contact
                  support@callinmarsat.com
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {this.getStepContent(activeStep, steps)}
              </React.Fragment>
            )}
          </Paper>
        </GridItem>
      </GridContainer>
    );
  }
}
CheckoutSection.propTypes = {
  classes: PropTypes.object,
  country: PropTypes.object,
  area: PropTypes.array,
};

export default withStyles(checkoutStyle)(CheckoutSection);
